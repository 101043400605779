import React, { Component } from "react";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";

export class ImportantInfo extends Component {
	state = {
		dancers: [],
		photo_dancers: [],
		medical_dancers: [],
		additional_dancers: [],
	};

	async componentDidMount() {
		var dancers = [];
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils", {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					dancers = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		var photo = dancers.filter((dancer) => dancer.photo_consent === "n");
		var medical = dancers.filter(
			(dancer) => !(dancer.medical_information === null || dancer.medical_information === "")
		);
		var additonal = dancers.filter(
			(dancer) => !(dancer.additional_info === null || dancer.additional_info === "")
		);
		this.setState({
			dancers: dancers,
			photo_dancers: photo,
			medical_dancers: medical,
			additional_dancers: additonal,
		});
	}

	render() {
		return (
			<div className="container bg-purple rounded padding margin-bottom">
				<div className="row justify-content-end">
					<div className="col">
						<h3 className="text-light-purple text-right">Important Information</h3>
					</div>
				</div>
				<div className="row">
					<div className="col rounded">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item">
								<button
									className="nav-link active bg-purple"
									id="photo-tab"
									data-bs-toggle="tab"
									data-bs-target="#photo"
									role="tab"
									type="button"
									aria-controls="photo"
									aria-selected="true"
								>
									<strong>
										{window.innerWidth < 770 ? "" : "Photo"}{" "}
										<i className="bi bi-camera-fill"></i>
									</strong>
								</button>
							</li>
							<li className="nav-item">
								<button
									className="nav-link bg-purple"
									id="medical-tab"
									data-bs-toggle="tab"
									data-bs-target="#medical"
									role="tab"
									type="button"
									aria-controls="medical"
									aria-selected="false"
								>
									<strong>
										{window.innerWidth < 770 ? "" : "Medical"}{" "}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											className="bi bi-bandaid-fill"
											viewBox="0 0 16 16"
										>
											<path d="m2.68 7.676 6.49-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Zm5.71-2.858a.5.5 0 1 0-.708.707.5.5 0 0 0 .707-.707ZM6.974 6.939a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707M5.56 8.354a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708m2.828 2.828a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707m1.414-2.121a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707m1.414-.707a.5.5 0 1 0-.706-.708.5.5 0 0 0 .707.708Zm-4.242.707a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707m1.414-.707a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708m1.414-2.122a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707M8.646 3.354l4 4 .708-.708-4-4zm-1.292 9.292-4-4-.708.708 4 4z" />
										</svg>
									</strong>
								</button>
							</li>
							<li className="nav-item">
								<button
									className="nav-link bg-purple"
									id="additional-tab"
									data-bs-toggle="tab"
									data-bs-target="#additional"
									role="tab"
									type="button"
									aria-controls="additional"
									aria-selected="false"
								>
									<strong>
										{window.innerWidth < 770 ? "" : "Additional Info"}{" "}
										<i className="bi bi-info-circle-fill"></i>
									</strong>
								</button>
							</li>
						</ul>

						<div
							className="tab-content bg-light-purple rounded-bottom margin-bottom"
							id="myTabContent"
						>
							<div
								className="tab-pane fade show active"
								id="photo"
								role="tabpanel"
								aria-labelledby="photo-tab"
							>
								<div className="container padding">
									<div className="row">
										<div className="col">
											The following dancers are not consented for photography:
										</div>
									</div>
									<div className="row">
										<div className="col">
											<div className="list-group list-group-flush">
												{this.state.photo_dancers.map((photo_dancer) => {
													return (
														<Link
															to={
																"/dancer/profile/" +
																photo_dancer.pupil_id
															}
															className="list-group-item list-group-item-action link-underline link-underline-opacity-0 text-purple"
															type="button"
														>
															{photo_dancer.first_name +
																" in " +
																photo_dancer.class_name}
														</Link>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade show"
								id="medical"
								role="tabpanel"
								aria-labelledby="medical-tab"
							>
								<div className="container padding">
									<div className="row">
										<div className="col">
											The following dancers have medical information:
										</div>
									</div>
									<div className="row">
										<div className="col">
											<div className="list-group list-group-flush">
												{this.state.medical_dancers.map((medical_dancer) => {
													return (
														<Link
															to={
																"/dancer/profile/" +
																medical_dancer.pupil_id
															}
															className="list-group-item list-group-item-action link-underline link-underline-opacity-0 text-purple"
															type="button"
														>
															{medical_dancer.first_name +
																" in " +
																medical_dancer.class_name}
														</Link>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade show"
								id="additional"
								role="tabpanel"
								aria-labelledby="additional-tab"
							>
								<div className="container padding">
									<div className="row">
										<div className="col">
											The following dancers have additional information:
										</div>
									</div>
									<div className="row">
										<div className="col">
											<div className="list-group list-group-flush">
												{this.state.additional_dancers.map((additional_dancer) => {
													return (
														<Link
															to={
																"/dancer/profile/" +
																additional_dancer.pupil_id
															}
															className="list-group-item list-group-item-action link-underline link-underline-opacity-0 text-purple"
															type="button"
														>
															{additional_dancer.first_name +
																" in " +
																additional_dancer.class_name}
														</Link>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ImportantInfo;
