import React, { Component } from "react";
import Navbar from "../../Common/Navbar";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export class OnboardingDashboard extends Component {
	state = {
		tempPupils: [],
		loading: false,
		msg: "",
		errorMsg: "",
		values: {
			parent_first_name: "",
			parent_last_name: "",
			email: "",
			child_first_name: "",
			child_last_name: "",
			dob: "",
			school_year: "Nursery",
		},
		school_years: ["Nursery", "Reception", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "Other"],
	};

	async componentDidMount() {
		var pupils = [];
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/temp-pupils", {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data !== null) {
						pupils = response.data;
					}
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({
			tempPupils: pupils,
			loading: false,
		});
	}
	submitValue = (event) => {
		event.preventDefault();
		this.setState({
			errorMsg: "",
		});
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (this.state.values[property] === "" || this.state.values[property] === " ") {
				// log to server
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
				});
				return;
			}
		}
		this.sendToBackend();
	};

	async sendToBackend() {
		this.setState({ loading: true });
		var msg;
		var body = {
			parent_email: this.state.values.email,
			first_name: this.state.values.child_first_name,
			last_name: this.state.values.child_last_name,
			parent_first_name: this.state.values.parent_first_name,
			parent_last_name: this.state.values.parent_last_name,
			// I absolutely hate that this is the way to do it
			dob: this.state.values.dob.replace("-", "").replace("-", ""),
			school_year: this.state.values.school_year,
		};
		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/temp-pupils/create", body, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Email Sent";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		if (name === "dob") {
			let dob = value.charAt(8) + value.charAt(9) + value.charAt(5) + value.charAt(6) + value.charAt(0) + value.charAt(1) + value.charAt(2) + value.charAt(3);
			this.setState({ values: { ...this.state.values, [name]: dob } });
		} else {
			this.setState({ values: { ...this.state.values, [name]: value } });
		}
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	getDoB(dob) {
		return dob.charAt(4) + dob.charAt(5) + dob.charAt(6) + dob.charAt(7) + "-" + dob.charAt(2) + dob.charAt(3) + "-" + dob.charAt(0) + dob.charAt(1);
	}

	getDoBToDisplay(dob) {
		return dob.charAt(0) + dob.charAt(1) + "/" + dob.charAt(2) + dob.charAt(3) + "/" + dob.charAt(4) + dob.charAt(5) + dob.charAt(6) + dob.charAt(7);
	}

	handleResendEmail = async (temp_pupil_id) => {
		this.setState({ loading: true });
		await axios
			.post(
				process.env.REACT_APP_API_END_POINT + "/api/temp-pupils/" + temp_pupil_id + "/resend",
				{},
				{
					headers: {
						Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
					},
				}
			)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					this.componentDidMount();
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
	};

	render() {
		return (
			<div>
				<Navbar title="Onboarding Management" />
				<div className="container">
					<div className="row">
						<div className="col-md col-11 margin rounded bg-purple">
							<div className="container padding">
								<div className="row">
									<div className="col text-right">
										<h3>Dashboard</h3>
									</div>
								</div>
								<div className="row">
									<div className="col padding rounded bg-light-purple">
										This is the Onboarding Management Dashboard. Here you can view current temporary dancers that have been sent links to complete their onboarding forms.
										You can also generate a new link for existing temporary dancers if parents require it. Finally, you can add new temporary dancers by completing the
										form.
									</div>
								</div>
							</div>
						</div>
						<div className="col-md col-11 margin rounded bg-purple">
							<div className="container padding ">
								<div className="row">
									<div className="col text-right">
										<h3>Add a New Dancer</h3>
									</div>
								</div>
								<div className="row">
									<div className="col padding rounded bg-light-purple">
										Click the button below to add a new temporary dancer so that their parent can complete the onboarding form. Please ensure the information on this form
										is accurate as some items cannot be changed during the onboarding process.
										<h4 className="text-center margin-top">
											<button type="button" data-bs-toggle="modal" data-bs-target="#addItem" className="btn bg-purple">
												Add Dancer
											</button>
										</h4>
										<div className="modal fade" id="addItem" tabIndex="-1" aria-labelledby="addItemLabel" aria-hidden="true">
											<div className="modal-dialog modal-lg">
												<div className="modal-content bg-purple">
													<div className="modal-header">
														<h5 className="modal-title" id="addItemLabel">
															Add New Dancer
														</h5>
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
															onClick={() => this.componentDidMount()}
														></button>
													</div>
													<div className="modal-body bg-light-purple margin rounded">
														<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
															<div className="container">
																<div className="row">
																	<div className="col text-center padding">
																		Please input data as seen on the waiting list. All information is required. Once submitted, the parent will
																		receive an email to create an account or login and onboard their child.
																	</div>
																</div>
																<div className="row justify-content-center">
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="parent_first_name"
																						placeholder="Parent First Name"
																						onChange={this.handleInputChange}
																						value={this.state.values.parent_first_name}
																						required
																					/>
																					<label htmlFor="parent_first_name" className="login-text-override">
																						Parent First Name
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="parent_last_name"
																						placeholder="Parent Last Name"
																						onChange={this.handleInputChange}
																						value={this.state.values.parent_last_name}
																						required
																					/>
																					<label htmlFor="parent_last_name" className="login-text-override">
																						Parent Last Name
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-12 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="email"
																						className="form-control"
																						id="email"
																						placeholder="Email"
																						onChange={this.handleInputChange}
																						value={this.state.values.email}
																						required
																					/>
																					<label htmlFor="email" className="login-text-override">
																						Email
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="child_first_name"
																						placeholder="child_first_name"
																						onChange={this.handleInputChange}
																						value={this.state.values.child_first_name}
																						required
																					/>
																					<label htmlFor="child_first_name" className="login-text-override">
																						Child First Name
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="child_last_name"
																						placeholder="Confirm Password"
																						onChange={this.handleInputChange}
																						value={this.state.values.child_last_name}
																						required
																					/>
																					<label htmlFor="child_last_name" className="login-text-override">
																						Child Last Name
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="date"
																						id="dob"
																						className="form-control"
																						onChange={this.handleInputChange}
																						value={this.state.values.dob === "" ? "" : this.getDoB(this.state.values.dob)}
																						required
																					/>
																					<label htmlFor="dob" className="login-text-override">
																						Date Of Birth
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<select
																						className="form-select"
																						aria-label="Default select example"
																						id="school_year"
																						onChange={this.handleInputChange}
																						value={this.state.values.school_year}
																					>
																						{this.state.school_years.map((year, index) => {
																							return (
																								<option value={year} key={index}>
																									{year}
																								</option>
																							);
																						})}
																					</select>
																					<label htmlFor="school_year" className="login-text-override">
																						School Year
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col">{this.getError()}</div>
																	</div>
																	<div className="row">
																		<div className="col text-right padding">
																			{this.state.msg}{" "}
																			{this.state.loading ? (
																				<div className="spinner-border" role="status">
																					<span className="sr-only"></span>
																				</div>
																			) : (
																				""
																			)}
																			<button className="btn bg-purple" type="submit" disabled={this.state.loading}>
																				Submit
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row">
									<div className="col text-right"></div>
								</div>
								<div className="row">
									<div className="col bg-purple">
										<div className="container padding">
											<div className="row">
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>
														<strong>Child Name</strong>
													</p>
												</div>
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>
														<strong>Date of Birth</strong>
													</p>
												</div>
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>
														<strong>Parent Name</strong>
													</p>
												</div>
												<div className={window.innerWidth < 770 ? "col-2 padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>
														<strong> </strong>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center padding">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.tempPupils.map((dancer, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-sm margin-right-sm"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
											>
												<div className="container margin-top-bottom">
													<div className="row align-items-center">
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{dancer.first_name + " " + dancer.last_name}</strong>
														</div>
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{this.getDoBToDisplay(dancer.dob)}</strong>
														</div>
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{dancer.parent_first_name + " " + dancer.parent_last_name}</strong>
														</div>
														<div
															className={
																window.innerWidth < 770 ? "col-2 text-break text-wrap padding-right-2 padding-left-2 text-right" : "col text-right"
															}
														>
															<button
																type="button"
																className="btn btn-lg"
																onClick={() => this.handleResendEmail(dancer.temp_pupil_id)}
																disabled={this.state.loading}
															>
																<i className="bi bi-arrow-clockwise"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OnboardingDashboard;
