import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Review extends Component {
	state = {
		parent: this.props.onboarding_state.parent,
		child: this.props.onboarding_state.child,
		errorMsg: this.props.onboarding_state.errorMsg,
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();

		this.props.submit();
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	getDoB(dob) {
		return (
			dob.charAt(0) +
			dob.charAt(1) +
			"-" +
			dob.charAt(2) +
			dob.charAt(3) +
			"-" +
			dob.charAt(4) +
			dob.charAt(5) +
			dob.charAt(6) +
			dob.charAt(7)
		);
	}

	getParentAccountCreationForm() {
		if (!this.state.parent.alreadyRegistered) {
			return (
				<div>
					<div className="row margin-left-right">
						<div className="col">
							Parent Account Creation
							<button type="button" className="btn btn-lg" onClick={() => this.props.goToPage(1)}>
								<i className="bi bi-pencil-fill"></i>
							</button>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="first_name"
										placeholder="First Name"
										onChange={this.handleInputChange}
										value={this.state.parent.first_name}
										disabled
									/>
									<label htmlFor="first_name" className="login-text-override">
										First Name
									</label>
								</div>
								<div className="invalid-feedback">Please enter your first name.</div>
							</div>
						</div>
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="last_name"
										placeholder="Last Name"
										onChange={this.handleInputChange}
										value={this.state.parent.last_name}
										disabled
									/>
									<label htmlFor="last_name" className="login-text-override">
										Last Name
									</label>
								</div>
								<div className="invalid-feedback">Please enter your last name.</div>
							</div>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="email"
										placeholder="Email"
										onChange={this.handleInputChange}
										value={this.state.parent.email}
										disabled
									/>
									<label htmlFor="email" className="login-text-override">
										Email
									</label>
								</div>
								<div className="invalid-feedback">Please enter your email.</div>
							</div>
						</div>
					</div>
					<div className="row margin-left-right">
						<div className="col">
							Parent Contact Information
							<button type="button" className="btn btn-lg" onClick={() => this.props.goToPage(2)}>
								<i className="bi bi-pencil-fill"></i>
							</button>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="phone_number"
										placeholder="Telephone"
										onChange={this.handleInputChange}
										value={this.state.parent.phone_number}
										disabled
									/>
									<label htmlFor="phone_number" className="login-text-override">
										Telephone
									</label>
								</div>
								<div className="invalid-feedback">Please enter your telephone.</div>
							</div>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="address_line1"
										placeholder="Address1"
										onChange={this.handleInputChange}
										value={this.state.parent.address_line1}
										disabled
									/>
									<label htmlFor="address_line1" className="login-text-override">
										Address Line 1
									</label>
								</div>
								<div className="invalid-feedback">Please enter your Address.</div>
							</div>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="address_line2"
										placeholder="Address2"
										onChange={this.handleInputChange}
										value={this.state.parent.address_line2}
										disabled
									/>
									<label htmlFor="address_line2" className="login-text-override">
										Address Line 2
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="town"
										placeholder="town"
										onChange={this.handleInputChange}
										value={this.state.parent.town}
										disabled
									/>
									<label htmlFor="town" className="login-text-override">
										Town
									</label>
								</div>
								<div className="invalid-feedback">Please enter your town.</div>
							</div>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="county"
										placeholder="county"
										onChange={this.handleInputChange}
										value={this.state.parent.county}
										disabled
									/>
									<label htmlFor="county" className="login-text-override">
										County
									</label>
								</div>
								<div className="invalid-feedback">Please enter your county.</div>
							</div>
						</div>
					</div>
					<div className="row margin">
						<div className="col">
							<div className="input-group has-validation">
								<div className="form-floating">
									<input
										type="text"
										className="form-control"
										id="postcode"
										placeholder="postcode"
										onChange={this.handleInputChange}
										value={this.state.parent.postcode}
										disabled
									/>
									<label htmlFor="postcode" className="login-text-override">
										Postcode
									</label>
								</div>
								<div className="invalid-feedback">Please enter your postcode.</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<h3 className="text-right">Onboarding Information Review</h3>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col text-center margin-top-bottom">
												Please review the information provided carefully. Once you
												are happy, please press submit to send the information to a
												member of management to approve.
											</div>
										</div>

										<div className="row">
											<div className="col margin-top-bottom">
												<strong>Child Basic Information</strong>
												<button
													type="button"
													className="btn btn-lg"
													onClick={() => this.props.goToPage(3)}
												>
													<i className="bi bi-pencil-fill"></i>
												</button>
											</div>
										</div>

										<div className="row">
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="first_name"
															placeholder="First Name"
															onChange={this.handleInputChange}
															value={this.state.child.first_name}
															disabled
														/>
														<label
															htmlFor="first_name"
															className="login-text-override"
														>
															First Name
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your first name.
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="last_name"
															placeholder="Last Name"
															onChange={this.handleInputChange}
															value={this.state.child.last_name}
															disabled
														/>
														<label
															htmlFor="last_name"
															className="login-text-override"
														>
															Last Name
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your last name.
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															id="dob"
															className="form-control"
															onChange={this.handleInputChange}
															value={this.getDoB(this.state.child.dob)}
															disabled
														/>
														<label
															htmlFor="dob"
															className="login-text-override"
														>
															Date Of Birth
														</label>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="school_year"
															placeholder="school_year"
															onChange={this.handleInputChange}
															value={this.state.child.school_year}
															disabled
														/>
														<label
															htmlFor="school_year"
															className="login-text-override"
														>
															School Year
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your school year.
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="primary_nok_relation"
															placeholder="relation"
															onChange={this.handleInputChange}
															value={this.state.child.primary_nok_relation}
															disabled
														/>
														<label
															htmlFor="floatingName"
															className="login-text-override"
														>
															Relationship To Child
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your relation.
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col margin-top-bottom">
												<strong>Child Medical Information</strong>
												<button
													type="button"
													className="btn btn-lg"
													onClick={() => this.props.goToPage(4)}
												>
													<i className="bi bi-pencil-fill"></i>
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<textarea
															type="text"
															className="form-control"
															id="medical_information"
															placeholder="medical_information"
															onChange={this.handleInputChange}
															value={this.state.child.medical_information}
															disabled
														/>
														<label
															htmlFor="medical_information"
															className="login-text-override"
														>
															Medical Informaiton
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your child's medical information.
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col  margin-top-bottom">
												<strong>Secondary Next Of Kin</strong>
												<button
													type="button"
													className="btn btn-lg"
													onClick={() => this.props.goToPage(5)}
												>
													<i className="bi bi-pencil-fill"></i>
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col  margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="nok_2_name"
															placeholder="First and Last Name"
															onChange={this.handleInputChange}
															value={this.state.child.nok_2_name}
															disabled
														/>
														<label
															htmlFor="nok_2_name"
															className="login-text-override"
														>
															First and Last Name
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="nok_2_phone"
															placeholder="phone_number"
															onChange={this.handleInputChange}
															value={this.state.child.nok_2_phone}
															disabled
														/>
														<label
															htmlFor="nok_2_phone"
															className="login-text-override"
														>
															Phone Number
														</label>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="nok_2_relation"
															placeholder="relation"
															onChange={this.handleInputChange}
															value={this.state.child.nok_2_relation}
															disabled
														/>
														<label
															htmlFor="nok_2_relation"
															className="login-text-override"
														>
															Relationship to child
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<strong>Child Misc Information</strong>
												<button
													type="button"
													className="btn btn-lg"
													onClick={() => this.props.goToPage(6)}
												>
													<i className="bi bi-pencil-fill"></i>
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														id="photo_consent"
														onChange={this.handleInputChange}
														checked={this.state.child.photo_consent === "y"}
														disabled
													/>
													<label
														className="form-check-label"
														htmlFor="photo_consent"
													>
														<strong>Consent For Photos</strong>
													</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<select
													className="form-select"
													aria-label="Default select example"
													id="shirt_size"
													onChange={this.handleInputChange}
													value={this.state.child.shirt_size}
													disabled
												>
													<option value="3-4">3 - 4</option>
													<option value="5-6">5 - 6</option>
													<option value="7-8">7 - 8</option>
													<option value="9-11">9 - 11</option>
													<option value="12-13">12 - 13</option>
													<option value="14-15">14 - 15</option>
													<option value="s">S</option>
													<option value="m">M</option>
													<option value="l">L</option>
													<option value="xl">XL</option>
													<option value="xxl">XXL</option>
												</select>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<textarea
															type="text"
															className="form-control"
															id="additional_info"
															placeholder="Other Information"
															onChange={this.handleInputChange}
															value={this.state.child.additional_info}
															disabled
														/>
														<label
															htmlFor="additional_info"
															className="login-text-override"
														>
															Additional Information
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<strong>Phoenix Dancer Rules</strong>
												<button
													type="button"
													className="btn btn-lg"
													onClick={() => this.props.goToPage(7)}
												>
													<i className="bi bi-pencil-fill"></i>
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														id="dancer_rules_accepted"
														onChange={this.handleInputChange}
														checked={
															this.state.child.dancer_rules_accepted ===
															"y"
														}
														disabled
													/>
													<label
														className="form-check-label"
														htmlFor="dancer_rules_accepted"
													>
														<strong>
															I, and my child, accept the Phoenix dancer
															rules.
														</strong>
													</label>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col margin-top-bottom">{this.getError()}</div>
										</div>
										<div className="row">
											{this.state.errorMsg === "Successful Onboarded" ? (
												<div className="col margin-top-bottom text-right">
													<Link className="btn bg-purple" to="/">
														Login
													</Link>
												</div>
											) : (
												<>
													<div className="col">
														<button
															className="btn bg-purple"
															onClick={() => this.decreaseStage()}
														>
															Back
														</button>
													</div>
													<div className="col text-right">
														<button className="btn bg-purple" type="submit">
															Submit
														</button>
													</div>
												</>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Review;
