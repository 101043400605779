import React, { Component } from "react";

export class SecondaryNextOfKin extends Component {
	state = {
		values: {
			nok_2_name: this.props.onboarding_state.child.nok_2_name,
			nok_2_phone: this.props.onboarding_state.child.nok_2_phone,
			nok_2_relation: this.props.onboarding_state.child.nok_2_relation,
		},
		errorMsg: "",
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();
		this.props.increaseStage(5, this.state.values);
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Second Next Of Kin
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												Here you can provide basic secondary next of kin
												information. This section is optional. You can add this
												information later.
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="nok_2_name"
															placeholder="First and Last Name"
															onChange={this.handleInputChange}
															value={this.state.values.nok_2_name}
														/>
														<label
															htmlFor="nok_2_name"
															className="login-text-override"
														>
															First and Last Name
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="nok_2_phone"
															placeholder="phone_number"
															onChange={this.handleInputChange}
															value={this.state.values.nok_2_phone}
														/>
														<label
															htmlFor="nok_2_phone"
															className="login-text-override"
														>
															Phone Number
														</label>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="nok_2_relation"
															placeholder="relation"
															onChange={this.handleInputChange}
															value={this.state.values.nok_2_relation}
														/>
														<label
															htmlFor="nok_2_relation"
															className="login-text-override"
														>
															Relationship to child
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">{this.getError()}</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<button
													className="btn bg-purple"
													onClick={() => this.decreaseStage()}
												>
													Back
												</button>
											</div>
											<div className="col margin-top-bottom text-right">
												<button className="btn bg-purple" type="submit">
													Next
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SecondaryNextOfKin;
