import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export class Parent extends Component {
	state = {
		name: "",
		dancers: [],
		loading: true,
	};

	async componentDidMount() {
		this.setState({ loading: true });
		var user = {};
		var dancers = [];
		var userId = jwtDecode(
			CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
		).sub;
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/users/" + userId, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					user = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/user/" + userId, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					dancers = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});

		this.setState({
			name: user.first_name,
			dancers: dancers,
			loading: false,
		});
	}

	render() {
		return (
			<div>
				<Navbar title="Parent Dashboard" />
				<div className="container rounded padding">
					<div className="row justify-content-center align-items-center">
						<div className="col-12 col-md-8">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div className="col">
										<h3 className="text-light-purple text-right">
											Welcome {this.state.name}!
										</h3>
									</div>
								</div>
								<div className="row">
									<div className="col bg-light-purple rounded margin-left margin-right">
										<p className="margin-top">
											Welcome to the Phoenix Amateur Dance Group Administration Site
											Parent Dashboard. From here you can complete a variety of actions
											such as amend your details as well as your dancer details.
											<br />
											Click on a dancer to see their profile. You can also click the
											navigation button on the top of the screen and edit your personal
											details.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center align-items-center">
						<div className="col-12 col-md-8">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div className="col">
										<h3 className="text-light-purple text-right">My Dancers</h3>
									</div>
								</div>
								<div className={window.innerWidth < 770 ? "row" : "row padding"}>
									<div
										className={
											window.innerWidth < 770
												? "col bg-purple margin-left-2 margin-right-2"
												: "col bg-purple"
										}
									>
										<div className="row">
											<div className="col">
												<p className={window.innerWidth < 770 ? "h5" : "h4"}>
													<strong>First Name</strong>
												</p>
											</div>
											<div className="col">
												<p className={window.innerWidth < 770 ? "h5" : "h4"}>
													<strong>Last Name</strong>
												</p>
											</div>
											<div className="col">
												<p className={window.innerWidth < 770 ? "h5" : "h4"}>
													<strong>Class</strong>
												</p>
											</div>
											<div className="col">
												<p className={window.innerWidth < 770 ? "h5" : "h4"}>
													<strong>Teacher</strong>
												</p>
											</div>
											{window.innerWidth < 770 ? (
												""
											) : (
												<div className="col">
													<p className={window.innerWidth < 770 ? "h5" : "h4"}>
														<strong> </strong>
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.dancers.map((dancer, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-2 margin-right-2"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
												onClick={() => {
													window.location.href =
														"/dancer/profile/" + dancer.pupil_id;
												}}
											>
												<div className="row align-items-center">
													<div className="col">
														<strong>{dancer.first_name}</strong>
													</div>
													<div className="col">
														<strong>{dancer.last_name}</strong>
													</div>
													<div className="col">
														<strong>
															{dancer.class_id
																? dancer.class_name +
																  "at" +
																  dancer.start_time
																: ""}
														</strong>
													</div>
													<div className="col">
														<strong>{dancer.teacher_name}</strong>
													</div>
													{window.innerWidth < 770 ? (
														""
													) : (
														<div className="col text-right">
															<Link
																type="button"
																className="btn btn-lg"
																to={
																	"/dancer/profile/" +
																	dancer.pupil_id
																}
															>
																<i className="bi bi-pencil-fill"></i>
															</Link>
														</div>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Parent;
