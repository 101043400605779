import React, { Component } from "react";

export class DancerRules extends Component {
	state = {
		values: {
			dancer_rules_accepted: this.props.onboarding_state.child.dancer_rules_accepted,
		},
		errorMsg: "",
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();
		this.props.increaseStage(7, this.state.values);
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.checked;
		if (name === "dancer_rules_accepted") {
			this.setState({
				values: { ...this.state.values, [name]: event.target.checked ? "y" : "n" },
			});
		} else {
			this.setState({ values: { ...this.state.values, [name]: value } });
		}
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Phoenix Dancer Rules
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col margin-top-bottom text-center">
												Please read the dancer rules carefully and ensure they are
												understood. Once you are happy, please click the checkbox at
												the bottom of the page. You will not be able to join Phoenix
												unless these rules are accepted.
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<h4>
													<strong>General Dancer Rules</strong>
												</h4>
												We have a one-off £20 membership fee, once paid and
												membership from is completed you will receive your Phoenix
												dance T-Shirt. By signing the rules we assume you will
												participate in the show. If you choose to leave prior to the
												show you will be invoiced for costumes bought for you
												up-to-date.
												<br />
												<br />
												<strong>What we will do for you: </strong>
												<ul>
													<li>
														Provide a fun, safe and affordable dance group
														which work towards an annual show.
													</li>
													<li>
														Always give you as much notice as possible if a
														lesson is cancelled due to illness, school
														closures or unforeseen circumstances.
													</li>
													<li>
														Respect you as dancers and utilise your individual
														skills.
													</li>
													<li>
														Listen to your opinions and ideas with dances,
														themes and music choices.
													</li>
													<li>
														Place you in an age appropriate class, however, in
														some circumstances we may make exceptions in the
														dancers best interests.{" "}
														<strong>
															This is at the discretion of the leaders.
														</strong>
													</li>
													<li>
														Provide basic first aid where necessary. All
														members of the management team are trained in
														basic first aid.
													</li>
												</ul>
												<br />
												<strong>What we expect from you:</strong>
												<ul>
													<li>Good attendance.</li>
													<li>
														As much notice as possible to your leader if you
														are unable to attend a lesson.
													</li>
													<li>Always respect your dance leaders and peers.</li>
													<li>Arrive prepared to dance in good time.</li>
													<li>
														Appropriate dance wear to be worn at all times,
														preferably Phoenix dance clothes with hair tied up
														off your face, no denim!
													</li>
													<li>
														Pay your fees as agreed in your registration, for
														every month in arrears there will be a £5 charge.
														If you leave Phoenix and then rejoin you will have
														to pay your membership fee again.
													</li>
													<li>
														Good behaviour is expected from each dancer -
														please see our behavioural policy below.
													</li>
													<li>
														Mobile phones, if brought to class are not to be
														used during the session and should be on silent.
													</li>
													<li>
														Look after your valuables; you are personally
														responsible for your things so please don’t bring
														anything too fragile or valuable to lessons and
														leaving in changing rooms at your own risk.
													</li>
													<li>
														Support and help your leaders and remember we are
														a team.
													</li>
													<li>
														Always bring a drink, preferably water to keep you
														hydrated during lessons especially in the warmer
														months.
													</li>
													<li>
														If you choose to arrive early for lessons please
														wait outside the building. Phoenix leaders will
														not be responsible for dancers whereabouts and
														safety outside of their dance class time. We
														therefore ask that parents collect their dancers
														in good time once their lesson has finished.
													</li>
													<li>
														If you choose to leave Phoenix please inform your
														leader ASAP, cancelling your standing order is
														your responsibility and{" "}
														<strong>Phoenix will not offer refunds.</strong>
													</li>
													<li>
														Costumes are provided for you for the show unless
														otherwise stated, these are given to you to alter
														accordingly but also take care of. Any damage or
														loss will be charged as well as excessive
														laundering.
													</li>
												</ul>
												<br />
												<strong>Other expectations; </strong>
												<ul>
													<li>
														Long term sickness (more than 2 weeks) will not be
														expected to pay fees if discussed with leader.
													</li>
													<li>
														Fees will be expected even if you miss lessons
														through holidays or other activities as studio
														will still be booked and payed for.
													</li>
												</ul>
												<h4>
													<strong>Behaviour Policy</strong>
												</h4>
												<ol>
													<li>
														<strong>
															<u>
																Respect all members of the Phoenix Team
															</u>
														</strong>
														<br />
														When a leader is talking, stop talking, listen and
														be ready. Follow any given instructions when they
														are given to you. This also applies to all helpers
														who must be given the same respect as you would
														your regular leader.
													</li>
													<li>
														<strong>
															<u>Respect others and their property</u>
														</strong>
														<br />
														Keep your hands to yourself, fighting and bullying
														is strictly prohibited at Phoenix Amateur Dance
														Group. Don’t take or use others personal
														belongings such as mobile phones, water bottles or
														anything brought from home.
													</li>
													<li>
														<strong>
															<u>No mobile phones</u>
														</strong>
														<br />
														Phoenix Amateur Dance Group is about having fun
														and being creative which is why we have no mobile
														phone use during lessons. Mobile phones are to be
														put away at the beginning of the lesson for safe
														keeping, they can be used in case of emergency or
														recording the dance for personal practice.
														However, it must be put away for the rest of the
														lesson. Phoenix Amateur Dance Group is not
														responsible for any loss/ damage or theft to any
														personal belongings – they are brought at your own
														risk.
													</li>
													<li>
														<strong>
															<u>Listen to what others are saying</u>
														</strong>
														<br />
														Class discussion is an important part of Phoenix
														Amateur Dance Group. Do not make fun of or upset
														another dancer when they are trying to talk,
														perform or ask a question. Use the appropriate
														behaviour at the appropriate time and refrain from
														talking excessively or at inappropriate times or
														adding comments which are not relevant to the
														discussion or class.
													</li>
													<li>
														<strong>
															<u>Attendance</u>
														</strong>
														<br />
														Attendance is important if you want to get the
														most out of Phoenix dance lessons. If you are not
														able to attend a lesson you must inform the
														management team as soon as possible via email. We
														will query after two unnotified absences. Three
														unnotified absences will result in the dancer
														losing their space. When absent from lessons, what
														dancers miss is their own responsibility to catch
														up on. Leaders are not required to reteach missed
														choreography.
													</li>
													<li>
														<strong>
															<u>
																Never use offensive or objectionable
																language
															</u>
														</strong>
														<br />
														NO SWEARING! The Phoenix Amateur Dance Group Team
														does not use foul language and we do not expect to
														hear it from our dancers or parents/guardians
														either!
													</li>
													<li>
														<strong>
															<u>Behaviour at the show</u>
														</strong>
														<br />
														The Phoenix Amateur Dance Group rules apply at all
														Phoenix events. However, these additional rules
														apply at the show for both dancers and responsible
														adults.
														<ul>
															<li>
																Respect and look after your costumes –
																damage or loss of costume will result in
																a fine to cover the cost of your costume
															</li>
															<li>
																Respect the building, facilities and
																staff at the show venue during
																rehearsals and the show.
															</li>
															<li>No smoking or vaping on premises</li>
															<li>
																If problems arise please inform a member
																of the Phoenix team in the first
																instance – these people will be wearing
																black Phoenix t-shirts. The problem can
																then be dealt with accordingly. If we
																feel parents need to be informed, we
																will do this.
															</li>
															<li>
																All of the expected behaviours are still
																relevant at the show and we reserve the
																right to ask you to leave if
																misbehaviour occurs.
															</li>
															<li>
																Changing rooms – please keep your
																costumes and belongings tidy. Respect
																the space and leave it as you found it
															</li>
															<li>
																Phoenix Amateur Dance Group is not
																responsible for any loss, damage or
																stolen property.
															</li>
														</ul>
													</li>
												</ol>
												<h4>
													<strong>Phoenix Discipline Plan</strong>
												</h4>
												Each discipline situation is unique. Consequences may change
												depending on the situation however choosing not to follow
												these rules will result in the following:
												<strong>
													<ol>
														<li>
															A warning. Warnings may be verbal or written.
														</li>
														<li>
															Set aside from class – dancers may be asked
															to sit out for a portion of the lesson.
														</li>
														<li>
															Parents/guardians called in for a meeting.
														</li>
														<li>
															Repetitive, severe disruptions or
															bullying/cyber bullying will result in a
															dancer being removed from Phoenix Amateur
															Dance Group and will not be allowed to attend
															any future sessions. A refund will not be
															given under any circumstance.
														</li>
													</ol>
												</strong>
												(All negative behaviour, at dance, will be investigated and
												dealt with accordingly)
												<br />
												<br />
												<strong>Why the rules must be followed</strong> <br />
												The above guidelines are minimum requirements to maintain a
												fun, positive learning atmosphere at Phoenix Amateur Dance
												Group. If these rules are followed correctly it will allow
												the following:
												<strong>
													<ol>
														<li>A fun and friendly atmosphere is created.</li>
														<li>
															You will learn new things and have a good
															time.
														</li>
													</ol>
												</strong>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													id="dancer_rules_accepted"
													onChange={this.handleInputChange}
													checked={
														this.state.values.dancer_rules_accepted === "y"
													}
													required
												/>
												<label
													className="form-check-label"
													htmlFor="dancer_rules_accepted"
												>
													<strong>
														I, and my child, accept the Phoenix Dancer Rules,
														Behaviour Policy and Discipline Plan and accept
														any consequence as a result of breaching these
														conditions.
													</strong>
												</label>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<button
												className="btn bg-purple"
												onClick={() => this.decreaseStage()}
											>
												Back
											</button>
										</div>
										<div className="col margin-top-bottom text-right">
											<button className="btn bg-purple" type="submit">
												Next
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DancerRules;
