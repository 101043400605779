import React, { Component } from "react";

export class ChildMiscInfo extends Component {
	state = {
		values: {
			shirt_size: this.props.onboarding_state.child.shirt_size,
			additional_info: this.props.onboarding_state.child.additional_info,
			photo_consent: this.props.onboarding_state.child.photo_consent,
		},
		errorMsg: "",
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();
		if (this.state.values.shirt_size === "default" || this.state.values.shirt_size === "") {
			this.setState({ errorMsg: "Please select a t-shirt size" });
		} else {
			this.props.increaseStage(6, this.state.values);
		}
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		if (name === "photo_consent") {
			this.setState({
				values: { ...this.state.values, [name]: event.target.checked ? "y" : "n" },
			});
		} else {
			this.setState({ values: { ...this.state.values, [name]: value } });
		}
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Child Misc Information
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												This section of the onboarding form requires your child's
												miscellaneous information
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														id="photo_consent"
														onChange={this.handleInputChange}
														checked={this.state.values.photo_consent === "y"}
													/>
													<label
														className="form-check-label"
														htmlFor="photo_consent"
													>
														<strong>Consent For Photos</strong> - By ticking
														this box you allow your child to be in photos used
														by Phoenix for promotional and general use. If you
														require more information, please leave this blank
														and speak to a member of management team.
													</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<select
													className="form-select"
													aria-label="Default select example"
													id="shirt_size"
													onChange={this.handleInputChange}
													value={this.state.values.shirt_size}
													required
												>
													<option value="">T-Shirt Size</option>
													<option value="3-4">3 - 4</option>
													<option value="5-6">5 - 6</option>
													<option value="7-8">7 - 8</option>
													<option value="9-11">9 - 11</option>
													<option value="12-13">12 - 13</option>
													<option value="14-15">14 - 15</option>
													<option value="s">S</option>
													<option value="m">M</option>
													<option value="l">L</option>
													<option value="xl">XL</option>
													<option value="xxl">XXL</option>
												</select>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												Below is an additional information box. This will be seen by
												the management team when this onboarding form is approved.
												If there is any additional information you believe is needed
												to support the application please write it below.
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<textarea
															type="text"
															className="form-control"
															id="additional_info"
															placeholder="Other Information"
															onChange={this.handleInputChange}
															value={this.state.values.additional_info}
														/>
														<label
															htmlFor="additional_info"
															className="login-text-override"
														>
															Additional Information
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col margin-top-bottom">{this.getError()}</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<button
													className="btn bg-purple"
													onClick={() => this.decreaseStage()}
												>
													Back
												</button>
											</div>
											<div className="col margin-top-bottom text-right">
												<button className="btn bg-purple" type="submit">
													Next
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ChildMiscInfo;
