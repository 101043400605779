import axios from "axios";
import React, { Component } from "react";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export class ToDoList extends Component {
	state = {
		todolist: [],
		msg: "",
		loading: false,
	};

	async componentDidMount() {
		var todo;
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/todo", {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					todo = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({ todolist: todo, loading: false });
	}

	handleClickComplete = async (toDoId, pupilId) => {
		this.setState({ msg: "Changing State", loading: true });
		var pupil = {};
		var msg = "";
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + pupilId, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					pupil = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		if (pupil[toDoId] === "y") {
			pupil[toDoId] = "n";
		} else {
			pupil[toDoId] = "y";
		}
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + pupilId, pupil, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Saved!";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
		this.componentDidMount();
	};

	handleClickDelete = (id) => {
		var temp = this.state.todolist;
		temp.splice(id, 1);
		this.setState({ todolist: temp });
	};

	addItem = (event) => {
		var temp = this.state.todolist;
		var item = {
			id: temp.length,
			title: event.target.title.value,
			items: [{ id: 0, name: event.target.item.value, complete: false }],
		};
		temp[temp.length + 1] = item;
		this.setState({ todolist: temp });
	};

	getButtonColour(completed) {
		var className = "btn btn-";
		if (completed) {
			return className + "success";
		} else {
			return className + "danger";
		}
	}

	showBin(id) {
		if (id.items[0].complete && id.items[1].complete && id.items[2].complete) {
			return (
				<button type="button" className="btn btn-lg" onClick={() => this.handleClickDelete(id)}>
					<i className="bi bi-trash-fill"></i>
				</button>
			);
		}
	}

	getButtonIcon(completed) {
		if (completed) {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					className="bi bi-check-lg"
					viewBox="0 0 16 16"
				>
					<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					className="bi bi-x-lg"
					viewBox="0 0 16 16"
				>
					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
				</svg>
			);
		}
	}

	render() {
		return (
			<div className="container bg-purple rounded padding">
				<div className="row justify-content-end">
					<div className="col">
						<h3 className="text-right">To Do List</h3>
					</div>
				</div>
				<div className="row">
					<div
						className={
							window.innerWidth < 770
								? "col bg-light-purple rounded padding margin-sm"
								: "col bg-light-purple rounded padding margin"
						}
					>
						<div className="container text-center">
							<div className="row">
								<div className="col col-3">
									<strong>Name</strong>
								</div>
								<div className="col">
									<strong>T-Shirt</strong>
								</div>
								<div className="col">
									<strong>Class Assign</strong>
								</div>
								<div className="col">
									<strong>Payment</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.loading ? (
					<div className="row">
						<div className="col rounded padding margin text-center">
							<div className="spinner-border" role="status">
								<span className="sr-only"></span>
							</div>
						</div>
					</div>
				) : (
					this.state.todolist.map((pupil, index) => (
						<div className="row" key={index}>
							<div
								className={
									window.innerWidth < 770
										? "col bg-light-purple rounded padding margin-left-sm margin-right-sm margin-top"
										: "col bg-light-purple rounded padding margin"
								}
							>
								<div className="container text-center">
									<div className="row justify-content-center align-items-center">
										<div
											className="col col-3"
											onClick={() => {
												window.location.href = "/dancer/profile/" + pupil.pupil_id;
											}}
										>
											<strong>{pupil.first_name + " " + pupil.last_name}</strong>
										</div>
										<div className="col">
											<div className="justify-content-center">
												<button
													className={this.getButtonColour(
														pupil.shirt_ordered === "y"
													)}
													type="button"
													aria-label="Checkbox for complete task"
													onClick={() =>
														this.handleClickComplete(
															"shirt_ordered",
															pupil.pupil_id
														)
													}
												>
													{this.getButtonIcon(pupil.shirt_ordered === "y")}
												</button>
											</div>
										</div>
										<div className="col">
											<div className="justify-content-center">
												<button
													className={this.getButtonColour(
														pupil.class_id !== null
													)}
													type="button"
													aria-label="Checkbox for complete task"
													title="This should be changed directly in the profile"
												>
													{this.getButtonIcon(pupil.class_id !== null)}
												</button>
											</div>
										</div>
										<div className="col">
											<div className="justify-content-center">
												<button
													className={this.getButtonColour(
														pupil.membership_fee_paid === "y"
													)}
													type="button"
													aria-label="Checkbox for complete task"
													onClick={() =>
														this.handleClickComplete(
															"membership_fee_paid",
															pupil.pupil_id
														)
													}
												>
													{this.getButtonIcon(pupil.membership_fee_paid === "y")}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))
				)}
			</div>
		);
	}
}

export default ToDoList;
