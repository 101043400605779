import React, { Component } from "react";
import axios from "axios";

export class ParentContactInfo extends Component {
	state = {
		values: {
			phone_number: this.props.onboarding_state.parent.phone_number,
			address_line1: this.props.onboarding_state.parent.address_line1,
			address_line2: this.props.onboarding_state.parent.address_line2,
			town: this.props.onboarding_state.parent.town,
			county: this.props.onboarding_state.parent.county,
			postcode: this.props.onboarding_state.parent.postcode,
		},
		parent: this.props.onboarding_state.parent,
		id: this.props.onboarding_state.id,
		code: this.props.onboarding_state.code,
		errorMsg: "",
		loading: false,
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = async () => {
		this.setState({ loading: true });
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (
				(this.state.values[property] === "" || this.state.values[property] === " ") &&
				property !== "address_line2"
			) {
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
					loading: false,
				});
				return;
			}
		}
		if (isNaN(this.state.values.phone_number)) {
			this.setState({ errorMsg: "Phone number is not a number", loading: false });
		} else {
			var body = {
				first_name: this.state.parent.first_name,
				last_name: this.state.parent.last_name,
				email: this.state.parent.email,
				hashed_password: this.state.parent.password,
				contact_number: this.state.values.phone_number,
				first_line: this.state.values.address_line1,
				second_line: this.state.values.address_line2,
				town: this.state.values.town,
				county: this.state.values.county,
				postcode: this.state.values.postcode,
				temp_pupil_token: this.state.code,
				temp_pupil_id: Number(this.state.id),
			};
			var res = "";
			await axios
				.post(process.env.REACT_APP_API_END_POINT + "/api/users/create", body)
				.then((response) => {
					if (response.status === 200) {
						this.props.loginAndSubmitCookie(2, this.state.values, response.data);
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					res = error.response.data;
				});
		}
		this.setState({ errorMsg: res, loading: false });
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Parent Contact Information
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<div className="container padding">
									<div className="row">
										<div className="col text-center">
											This section of the onboarding form requires your information.
											Details of your child will be collected at a later point.
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="input-group has-validation">
												<div className="form-floating">
													<input
														type="text"
														className="form-control"
														id="phone_number"
														placeholder="Telephone"
														onChange={this.handleInputChange}
														value={this.state.values.phone_number}
														required
													/>
													<label
														htmlFor="phone_number"
														className="login-text-override"
													>
														Telephone
													</label>
												</div>
												<div className="invalid-feedback">
													Please enter your telephone.
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="input-group has-validation">
												<div className="form-floating">
													<input
														type="text"
														className="form-control"
														id="address_line1"
														placeholder="Address1"
														onChange={this.handleInputChange}
														value={this.state.values.address_line1}
														required
													/>
													<label
														htmlFor="address_line1"
														className="login-text-override"
													>
														Address Line 1
													</label>
												</div>
												<div className="invalid-feedback">
													Please enter your Address.
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="input-group has-validation">
												<div className="form-floating">
													<input
														type="text"
														className="form-control"
														id="address_line2"
														placeholder="Address2"
														onChange={this.handleInputChange}
														value={this.state.values.address_line2}
													/>
													<label
														htmlFor="address_line2"
														className="login-text-override"
													>
														Address Line 2
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="input-group has-validation">
												<div className="form-floating">
													<input
														type="text"
														className="form-control"
														id="town"
														placeholder="town"
														onChange={this.handleInputChange}
														value={this.state.values.town}
														required
													/>
													<label htmlFor="town" className="login-text-override">
														Town
													</label>
												</div>
												<div className="invalid-feedback">
													Please enter your town.
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="input-group has-validation">
												<div className="form-floating">
													<input
														type="text"
														className="form-control"
														id="county"
														placeholder="county"
														onChange={this.handleInputChange}
														value={this.state.values.county}
													/>
													<label
														htmlFor="county"
														className="login-text-override"
													>
														County
													</label>
												</div>
												<div className="invalid-feedback">
													Please enter your county.
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<div className="input-group has-validation">
												<div className="form-floating">
													<input
														type="text"
														className="form-control"
														id="postcode"
														placeholder="postcode"
														onChange={this.handleInputChange}
														value={this.state.values.postcode}
														required
													/>
													<label
														htmlFor="postcode"
														className="login-text-override"
													>
														Postcode
													</label>
												</div>
												<div className="invalid-feedback">
													Please enter your postcode.
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">{this.getError()}</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom text-center">
											Upon clicking 'Create Account' a parent account will be created
											using the information provided. If you wish to change any
											information, you will be able to do so later once you log in to
											the Parent Dashboard. Information for your child will be
											collected next.
										</div>
									</div>
									<div className="row">
										<div className="col margin-top-bottom">
											<button
												className="btn bg-purple"
												onClick={() => this.decreaseStage()}
											>
												Back
											</button>
										</div>
										<div className="col margin-top-bottom text-right">
											<button
												className="btn bg-purple"
												type="submit"
												disabled={this.state.loading}
												onClick={() => this.submitValue()}
											>
												{this.state.loading ? (
													<div className="spinner-border" role="status">
														<span className="sr-only"></span>
													</div>
												) : (
													"Create Account"
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ParentContactInfo;
