import React, { Component } from "react";

export class ParentAccountCreation extends Component {
	state = {
		values: {
			first_name: this.props.onboarding_state.parent.first_name,
			last_name: this.props.onboarding_state.parent.last_name,
			email: this.props.onboarding_state.parent.email,
			password: this.props.onboarding_state.parent.password,
			confirm_password: this.props.onboarding_state.parent.password,
		},
		errorMsg: "",
	};

	decreaseStage() {
		if (window.confirm("Are you sure you want to return to the landing page? All information will be lost.")) {
			this.props.decreaseStage();
		}
	}

	submitValue = (event) => {
		event.preventDefault();
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (this.state.values[property] === "" || this.state.values[property] === " ") {
				// log to server
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
				});
				return;
			}
		}
		if (this.state.values.password !== this.state.values.confirm_password) {
			this.setState({ errorMsg: "Passwords don't match" });
		} else if (this.state.values.password.length < 8) {
			this.setState({ errorMsg: "Password must be at least 8 characters" });
		} else {
			this.props.increaseStage(1, this.state.values);
		}
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Parent Account Creation
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												The information on this section was gathered from the
												waiting list. If something isn't correct, please continue
												and inform a member of the Management team. Your password
												must be at least 8 characters long.
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="row margin">
												<div className="col-12 col-md-6 margin-top-bottom">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="text"
																className="form-control"
																id="first_name"
																placeholder="First Name"
																onChange={this.handleInputChange}
																value={this.state.values.first_name}
																disabled
															/>
															<label
																htmlFor="first_name"
																className="login-text-override"
															>
																First Name
															</label>
														</div>
														<div className="invalid-feedback">
															Please enter your first name.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6 margin-top-bottom">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="text"
																className="form-control"
																id="last_name"
																placeholder="Last Name"
																onChange={this.handleInputChange}
																value={this.state.values.last_name}
																disabled
															/>
															<label
																htmlFor="last_name"
																className="login-text-override"
															>
																Last Name
															</label>
														</div>
														<div className="invalid-feedback">
															Please enter your last name.
														</div>
													</div>
												</div>
											</div>
											<div className="row margin">
												<div className="col">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="text"
																className="form-control"
																id="email"
																placeholder="Email"
																onChange={this.handleInputChange}
																value={this.state.values.email}
																disabled
															/>
															<label
																htmlFor="email"
																className="login-text-override"
															>
																Email
															</label>
														</div>
														<div className="invalid-feedback">
															Please enter your email.
														</div>
													</div>
												</div>
											</div>
											<div className="row margin">
												<div className="col-12 col-md-6 margin-top-bottom">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="password"
																className="form-control"
																id="password"
																placeholder="password"
																onChange={this.handleInputChange}
																value={this.state.values.password}
																required
															/>
															<label
																htmlFor="password"
																className="login-text-override"
															>
																Password
															</label>
														</div>
														<div className="invalid-feedback">
															Please enter your password.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6 margin-top-bottom">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="password"
																className="form-control"
																id="confirm_password"
																placeholder="Confirm Password"
																onChange={this.handleInputChange}
																value={
																	this.state.values.confirm_password
																}
																required
															/>
															<label
																htmlFor="confirm_password"
																className="login-text-override"
															>
																Confirm Password
															</label>
														</div>
														<div className="invalid-feedback">
															Please confirm your password.
														</div>
													</div>
												</div>
											</div>
											<div className="row margin">
												<div className="col">{this.getError()}</div>
											</div>
											<div className="row margin">
												<div className="col">
													<button
														className="btn bg-purple"
														onClick={() => this.decreaseStage()}
													>
														Back
													</button>
												</div>
												<div className="col text-right">
													<button className="btn bg-purple" type="submit">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ParentAccountCreation;
