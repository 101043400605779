import React, { Component } from "react";

export class ChildBasicInfo extends Component {
	state = {
		values: {
			first_name: this.props.onboarding_state.child.first_name,
			last_name: this.props.onboarding_state.child.last_name,
			dob: this.props.onboarding_state.child.dob,
			school_year: this.props.onboarding_state.child.school_year,
			primary_nok_relation: this.props.onboarding_state.child.primary_nok_relation,
		},
		errorMsg: "",
		school_years: ["Nursery", "Reception", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"],
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (this.state.values[property] === "" || this.state.values[property] === " ") {
				// log to server
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
				});
				return;
			}
		}
		// if (/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(this.state.values.dob)) {
		// 	this.setState({ errorMsg: "Date Of Birth is incorrect" });
		// }
		// we can do this just in case anybody overrides the dropdown values, but that would never make it past the backend anyway
		// else
		if (!this.state.school_years.includes(this.state.values.school_year)) {
			this.setState({ errorMsg: "School Year is not valid" });
		} else {
			this.props.increaseStage(3, this.state.values);
		}
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		if (name === "dob") {
			let newValue =
				value.charAt(8) +
				value.charAt(9) +
				value.charAt(5) +
				value.charAt(6) +
				value.charAt(0) +
				value.charAt(1) +
				value.charAt(2) +
				value.charAt(3);
			this.setState({ values: { ...this.state.values, [name]: newValue } });
		} else {
			this.setState({ values: { ...this.state.values, [name]: value } });
		}
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	getDoB(dob) {
		return (
			dob.charAt(0) +
			dob.charAt(1) +
			"-" +
			dob.charAt(2) +
			dob.charAt(3) +
			"-" +
			dob.charAt(4) +
			dob.charAt(5) +
			dob.charAt(6) +
			dob.charAt(7)
		);
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Child Basic Information
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												This section of the onboarding form requires your child's
												information. We have populated this from the waiting list.
												If something is incorrect, please continue and contact a
												member of the Management team.
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="first_name"
															placeholder="First Name"
															onChange={this.handleInputChange}
															value={this.state.values.first_name}
															disabled
														/>
														<label
															htmlFor="first_name"
															className="login-text-override"
														>
															Child's First Name
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your first name.
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="last_name"
															placeholder="Last Name"
															onChange={this.handleInputChange}
															value={this.state.values.last_name}
															disabled
														/>
														<label
															htmlFor="last_name"
															className="login-text-override"
														>
															Child's Last Name
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your last name.
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															id="dob"
															className="form-control"
															value={this.getDoB(this.state.values.dob)}
															disabled
														/>
														<label
															htmlFor="dob"
															className="login-text-override"
														>
															Child's Date Of Birth
														</label>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6 margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<select
															className="form-select"
															aria-label="Default select example"
															id="school_year"
															onChange={this.handleInputChange}
															value={this.state.values.school_year}
															disabled
														>
															{this.state.school_years.map(
																(year, index) => {
																	return (
																		<option
																			value={year}
																			key={index}
																		>
																			{year}
																		</option>
																	);
																}
															)}
														</select>
														<label
															htmlFor="school_year"
															className="login-text-override"
														>
															School Year
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your school year.
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<input
															type="text"
															className="form-control"
															id="primary_nok_relation"
															placeholder="relation"
															onChange={this.handleInputChange}
															value={
																this.state.values.primary_nok_relation
															}
															required
														/>
														<label
															htmlFor="primary_nok_relation"
															className="login-text-override"
														>
															Relationship To Child
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your relation.
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">{this.getError()}</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom"></div>
											<div className="col margin-top-bottom text-right">
												<button className="btn bg-purple" type="submit">
													Next
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ChildBasicInfo;
