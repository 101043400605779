import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export class DancerProfile extends Component {
	state = {
		id: this.props.id,
		values: {
			first_name: "",
			last_name: "",
			class: "",
			parent_first_name: "",
			parent_last_name: "",
			dob: "",
			medical_information: "",
			allergies: "",
			dancer_rules_accepted: false,
			school_year: "",
			nok_2_name: "",
			nok_2_phone: "",
			nok_2_relation: "",
			primary_nok_relation: "",
			additional_info: "",
			shirt_ordered: false,
			membership_fee_paid: false,
			shirt_size: "large",
			additional_info_last_author: "",
			updated_at: "",
		},
		classes: [],
		approved: "",
		age: "",
		parent: {},
		school_years: [
			"Nursery",
			"Reception",
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"11",
			"12",
			"13",
			"Other",
		],
		teacher: "",
		additional_info_changed: false,
		msg: "",
		loading: false,
		showDropdown: false,
		restrictEdit: CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY)
			.toString(CryptoJS.enc.Utf8)
			.includes("Teacher"),
		restrictMajorEdit: CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY)
			.toString(CryptoJS.enc.Utf8)
			.includes("Parent"),
	};

	async componentDidMount() {
		var pupil = {};
		var classesObj = [];

		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + this.state.id, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				pupil = response.data;
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});

		if (
			CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY)
				.toString(CryptoJS.enc.Utf8)
				.includes("Admin")
		) {
			await axios
				.get(process.env.REACT_APP_API_END_POINT + "/api/classes", {
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						classesObj = response.data;
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						window.location.replace("/error");
					}
				});
			classesObj.push({ class_id: 0, class_name: "Unassigned" });
			this.setState({ classes: classesObj, showDropdown: true });
		}

		let calcAge = this.getAge(this.getDoB(pupil.dob));
		pupil.pupil_id = this.state.id;
		var teacher_name = "No teacher assigned to class";
		if (pupil.teacher_first_name != null && pupil.teacher_first_name != null) {
			teacher_name = pupil.teacher_first_name + " " + pupil.teacher_last_name;
		}

		this.setState({
			values: {
				pupil_id: pupil.pupil_id,
				first_name: pupil.first_name,
				last_name: pupil.last_name,
				class: pupil.class_name,
				class_id: pupil.class_id == null ? 0 : pupil.class_id,
				dob: pupil.dob,
				medical_information: pupil.medical_information,
				dancer_rules_accepted: pupil.dancer_rules_accepted,
				school_year: pupil.school_year,
				nok_2_name: pupil.nok_2_name,
				nok_2_phone: pupil.nok_2_phone,
				nok_2_relation: pupil.nok_2_relation,
				primary_nok_relation: pupil.primary_nok_relation,
				additional_info: pupil.additional_info,
				shirt_ordered: pupil.shirt_ordered,
				membership_fee_paid: pupil.membership_fee_paid,
				shirt_size: pupil.shirt_size,
				additional_info_last_author: pupil.additional_info_last_author,
				photo_consent: pupil.photo_consent,
				updated_at: pupil.updated_at,
			},
			parent: {
				parent_id: pupil.user_id,
				first_name: pupil.parent_first_name,
				last_name: pupil.parent_last_name,
				email: pupil.email,
				contact_number: pupil.contact_number,
			},
			approved: pupil.approved,
			age: calcAge,
			teacher: pupil.class_id ? teacher_name : "No Class",
			loading: false,
		});
	}

	getAge(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		if (value === "NA") return;
		if (
			name === "dancer_rules_accepted" ||
			name === "membership_fee_paid" ||
			name === "shirt_ordered" ||
			name === "photo_consent"
		) {
			this.setState({
				values: { ...this.state.values, [name]: event.target.checked ? "y" : "n" },
				msg: "",
			});
		} else if (name === "additional_info") {
			this.setState({
				values: { ...this.state.values, [name]: value },
				additional_info_changed: true,
				msg: "",
			});
		} else if (name === "dob") {
			let dob =
				value.charAt(8) +
				value.charAt(9) +
				value.charAt(5) +
				value.charAt(6) +
				value.charAt(0) +
				value.charAt(1) +
				value.charAt(2) +
				value.charAt(3);
			this.setState({ values: { ...this.state.values, [name]: dob }, msg: "" });
		} else {
			this.setState({ values: { ...this.state.values, [name]: value }, msg: "" });
		}
	};

	getTeacher() {
		return "Someone";
	}

	getDoB(dob) {
		return (
			dob.charAt(4) +
			dob.charAt(5) +
			dob.charAt(6) +
			dob.charAt(7) +
			"-" +
			dob.charAt(2) +
			dob.charAt(3) +
			"-" +
			dob.charAt(0) +
			dob.charAt(1)
		);
	}

	submitInformation = async () => {
		this.setState({ loading: true });
		delete this.state.values.updated_at;
		var body = this.state.values;
		body.class_id = Number(this.state.values.class_id);
		body.class_id = body.class_id === 0 ? null : body.class_id;
		body.pupil_id = Number(this.state.id);

		if (this.state.additional_info_changed) {
			var userId = jwtDecode(
				CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
					CryptoJS.enc.Utf8
				)
			).sub;
			await axios
				.get(process.env.REACT_APP_API_END_POINT + "/api/users/" + userId, {
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						body.additional_info_last_author = response.data.first_name;
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						window.location.replace("/error");
					}
				});
		}
		var msg = "";
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + this.state.id, body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				msg = "Saved!";
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
		this.componentDidMount();
	};

	handleApprove = async () => {
		var body = { approved: "y" };
		var msg;
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + this.state.id, body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = response.data.first_name + " " + response.data.last_name + " Approved!";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg });
		this.componentDidMount();
	};

	getChangedByMessage() {
		var msg;
		if (
			this.state.values.additional_info_last_author == null ||
			this.state.values.additional_info_last_author === "" ||
			this.state.values.updated_at == null ||
			this.state.values.updated_at === ""
		) {
			msg = "";
		} else {
			let dateTime = this.state.values.updated_at.split("T");
			msg =
				"Changed by " +
				this.state.values.additional_info_last_author +
				" at " +
				dateTime[1].split(".")[0] +
				" on " +
				dateTime[0];
		}
		return msg;
	}

	render() {
		return (
			<div>
				<Navbar title="Dancer Profile" />
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="bg-purple rounded padding margin">
								<div className="bg-light-purple rounded margin text-center">
									<div className="container padding">
										<div className="row">
											<div className="col margin">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="160"
													height="160"
													fill="currentColor"
													className="bi bi-person-circle"
													viewBox="0 0 16 16"
												>
													<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
													<path
														fillRule="evenodd"
														d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
													/>
												</svg>
											</div>
										</div>
										<div className="row">
											<div className="col">
												{this.state.loading ? (
													<div className="d-flex justify-content-center">
														<div className="spinner-border" role="status">
															<span className="sr-only"></span>
														</div>
													</div>
												) : (
													""
												)}
												<strong>
													{this.state.values.first_name}{" "}
													{this.state.values.last_name}
												</strong>
											</div>
										</div>
										<div className="row">
											<div className="col">
												{this.state.values.class || "Unassigned"}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="bg-purple rounded padding margin">
								<ul className="nav nav-tabs" id="myTab" role="tablist">
									<li className="nav-item">
										<button
											className="nav-link active bg-purple"
											id="personal-tab"
											data-bs-toggle="tab"
											data-bs-target="#personal"
											role="tab"
											type="button"
											aria-controls="personal"
											aria-selected="true"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Personal"}{" "}
												<i className="bi bi-person-lines-fill"></i>
											</strong>
										</button>
									</li>
									{this.state.restrictEdit ? (
										""
									) : (
										<li className="nav-item">
											<button
												className="nav-link bg-purple"
												id="contact-tab"
												data-bs-toggle="tab"
												data-bs-target="#contact"
												role="tab"
												type="button"
												aria-controls="contact"
												aria-selected="false"
											>
												<strong>
													{window.innerWidth < 770 ? "" : "Contact"}{" "}
													<i className="bi bi-telephone-fill"></i>
												</strong>
											</button>
										</li>
									)}
									<li className="nav-item">
										<button
											className="nav-link bg-purple"
											id="medical-tab"
											data-bs-toggle="tab"
											data-bs-target="#medical"
											role="tab"
											type="button"
											aria-controls="medical"
											aria-selected="false"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Medical"}{" "}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													fill="currentColor"
													className="bi bi-heart-pulse-fill"
													viewBox="0 0 16 16"
												>
													<path d="M1.475 9C2.702 10.84 4.779 12.871 8 15c3.221-2.129 5.298-4.16 6.525-6H12a.5.5 0 0 1-.464-.314l-1.457-3.642-1.598 5.593a.5.5 0 0 1-.945.049L5.889 6.568l-1.473 2.21A.5.5 0 0 1 4 9z" />
													<path d="M.88 8C-2.427 1.68 4.41-2 7.823 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C11.59-2 18.426 1.68 15.12 8h-2.783l-1.874-4.686a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8z" />
												</svg>
											</strong>
										</button>
									</li>
									<li className="nav-item">
										<button
											className="nav-link bg-purple"
											id="class-tab"
											data-bs-toggle="tab"
											data-bs-target="#class"
											role="tab"
											type="button"
											aria-controls="class"
											aria-selected="false"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Class"}{" "}
												<i className="bi bi-calendar-date-fill"></i>
											</strong>
										</button>
									</li>
									<li className="nav-item">
										<button
											className="nav-link bg-purple"
											id="misc-tab"
											data-bs-toggle="tab"
											data-bs-target="#misc"
											role="tab"
											type="button"
											aria-controls="misc"
											aria-selected="false"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Misc"}{" "}
												<i className="bi bi-card-checklist"></i>
											</strong>
										</button>
									</li>
								</ul>
								<div className="tab-content bg-light-purple rounded-bottom" id="myTabContent">
									<div
										className="tab-pane fade show active"
										id="personal"
										role="tabpanel"
										aria-labelledby="personal-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col">First Name</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="first_name"
															placeholder="First Name"
															onChange={this.handleInputChange}
															value={this.state.values.first_name}
															disabled={
																this.state.restrictEdit ||
																this.state.restrictMajorEdit
															}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Last Name</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="last_name"
															placeholder="Last Name"
															onChange={this.handleInputChange}
															value={this.state.values.last_name}
															disabled={
																this.state.restrictEdit ||
																this.state.restrictMajorEdit
															}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-4">
													<div className="row">
														<div className="col">Date Of Birth</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<input
																	type="date"
																	id="dob"
																	className="form-control"
																	onChange={this.handleInputChange}
																	value={this.getDoB(
																		this.state.values.dob
																	)}
																	disabled={
																		this.state.restrictEdit ||
																		this.state.restrictMajorEdit
																	}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="row">
														<div className="col">School Year</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<select
																	className="form-select"
																	aria-label="Default select example"
																	id="school_year"
																	onChange={this.handleInputChange}
																	value={
																		this.state.values.school_year
																	}
																	disabled={this.state.restrictEdit}
																>
																	{this.state.school_years.map(
																		(year, index) => {
																			return (
																				<option
																					value={year}
																					key={index}
																				>
																					{year}
																				</option>
																			);
																		}
																	)}
																</select>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="row">
														<div className="col">Age</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<input
																	type="text"
																	className="form-control"
																	defaultValue={this.state.age}
																	aria-label="age"
																	disabled
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{this.state.restrictEdit ? (
										""
									) : (
										<div
											className="tab-pane fade"
											id="contact"
											role="tabpanel"
											aria-labelledby="contact-tab"
										>
											<div className="container padding">
												<div className="row">
													<div className="col align-content-center">
														<h4>
															Next Of Kin 1
															<Link
																type="button"
																className={
																	this.state.restrictEdit
																		? "btn btn-lg d-none"
																		: "btn btn-lg"
																}
																to={
																	"/user/profile/" +
																	this.state.parent.parent_id
																}
															>
																<i className="bi bi-pencil-fill"></i>
															</Link>
														</h4>
													</div>
												</div>
												<div className="row">
													<div className="col-12 col-md-6">
														<div className="row">
															<div className="col">First Name</div>
														</div>
														<div className="row">
															<div className="col">
																<div className="input-group mb-3">
																	<input
																		type="text"
																		className="form-control"
																		id="first_name"
																		placeholder="First Name"
																		onChange={
																			this.handleInputChange
																		}
																		value={
																			this.state.parent
																				.first_name
																		}
																		disabled
																	/>
																</div>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="row">
															<div className="col">Last Name</div>
														</div>
														<div className="row">
															<div className="col">
																<div className="input-group mb-3">
																	<input
																		type="text"
																		className="form-control"
																		id="last_name"
																		placeholder="Last Name"
																		onChange={
																			this.handleInputChange
																		}
																		value={
																			this.state.parent
																				.last_name
																		}
																		disabled
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">Email</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="input-group mb-3">
															<input
																type="text"
																className="form-control"
																id="email"
																placeholder="Email"
																onChange={this.handleInputChange}
																value={this.state.parent.email}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">Phone Number</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="input-group mb-3">
															<input
																type="text"
																className="form-control"
																id="phone_number"
																placeholder="Telephone"
																onChange={this.handleInputChange}
																value={this.state.parent.contact_number}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">Relationship</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="input-group mb-3">
															<input
																type="text"
																className="form-control"
																id="primary_nok_relation"
																placeholder="Relation"
																onChange={this.handleInputChange}
																value={
																	this.state.values
																		.primary_nok_relation
																}
																disabled={this.state.restrictEdit}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<h4>Next Of Kin 2</h4>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="row">
															<div className="col">Name</div>
														</div>
														<div className="row">
															<div className="col">
																<div className="input-group mb-3">
																	<input
																		type="text"
																		className="form-control"
																		id="nok_2_name"
																		placeholder="Not Provided"
																		onChange={
																			this.handleInputChange
																		}
																		value={
																			this.state.values
																				.nok_2_name
																		}
																		disabled={
																			this.state.restrictEdit
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">Phone Number</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="input-group mb-3">
															<input
																type="text"
																className="form-control"
																id="nok_2_phone"
																placeholder="Not Provided"
																onChange={this.handleInputChange}
																value={this.state.values.nok_2_phone}
																disabled={this.state.restrictEdit}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">Relationship</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="input-group mb-3">
															<input
																type="text"
																className="form-control"
																id="nok_2_relation"
																placeholder="Not Provided"
																onChange={this.handleInputChange}
																value={this.state.values.nok_2_relation}
																disabled={this.state.restrictEdit}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									<div
										className="tab-pane fade"
										id="medical"
										role="tabpanel"
										aria-labelledby="medical-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col">Medical Information</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<textarea
															type="text"
															className="form-control"
															id="medical_information"
															placeholder="None"
															onChange={this.handleInputChange}
															value={this.state.values.medical_information}
															disabled={this.state.restrictEdit}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										className="tab-pane fade"
										id="class"
										role="tabpanel"
										aria-labelledby="class-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col">Class</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														{this.state.showDropdown ? (
															<>
																<select
																	className="form-select"
																	aria-label="Default select example"
																	id="class_id"
																	onChange={this.handleInputChange}
																	value={this.state.values.class_id}
																>
																	{this.state.classes.map(
																		(classObj, index) => {
																			return (
																				<option
																					value={
																						classObj.class_id
																					}
																					key={index}
																				>
																					{
																						classObj.class_name
																					}
																				</option>
																			);
																		}
																	)}
																</select>
															</>
														) : (
															<input
																type="text"
																className="form-control"
																value={
																	this.state.values.class
																		? this.state.values.class
																		: "Unassigned"
																}
																aria-label="teacher"
																disabled
															/>
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Teacher</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															defaultValue={this.state.teacher}
															aria-label="teacher"
															disabled
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										className="tab-pane fade"
										id="misc"
										role="tabpanel"
										aria-labelledby="misc-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col padding">
													<input
														className="form-check-input"
														type="checkbox"
														id="dancer_rules_accepted"
														onChange={this.handleInputChange}
														checked={
															this.state.values.dancer_rules_accepted ===
															"y"
														}
														disabled
													/>{" "}
													<label
														className="form-check-label"
														htmlFor="dancer_rules_accepted"
													>
														Dancer Rules Accepted
													</label>
												</div>
											</div>

											{this.state.restrictEdit || this.state.restrictMajorEdit ? (
												""
											) : (
												<div className="row">
													<div className="col padding">
														<input
															className="form-check-input"
															type="checkbox"
															id="membership_fee_paid"
															onChange={this.handleInputChange}
															checked={
																this.state.values
																	.membership_fee_paid === "y"
															}
															disabled={this.state.restrictEdit}
														/>{" "}
														<label
															className="form-check-label"
															htmlFor="membership_fee_paid"
														>
															Subscription Paid
														</label>
													</div>
												</div>
											)}
											<div className="row">
												<div className="col padding">
													<input
														className="form-check-input"
														type="checkbox"
														id="photo_consent"
														onChange={this.handleInputChange}
														checked={this.state.values.photo_consent === "y"}
														disabled={this.state.restrictEdit}
													/>{" "}
													<label
														className="form-check-label"
														htmlFor="photo_consent"
													>
														Photo Consent
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col">T-Shirt</div>
											</div>
											<div className="row d-flex align-items-center">
												<div className="col">
													<div className="input-group mb-3">
														<select
															className="form-select"
															aria-label="Default select example"
															id="shirt_size"
															onChange={this.handleInputChange}
															value={this.state.values.shirt_size}
															disabled={this.state.restrictEdit}
														>
															<option value="3-4">3 - 4</option>
															<option value="5-6">5 - 6</option>
															<option value="7-8">7 - 8</option>
															<option value="9-11">9 - 11</option>
															<option value="12-13">12 - 13</option>
															<option value="14-15">14 - 15</option>
															<option value="s">S</option>
															<option value="m">M</option>
															<option value="l">L</option>
															<option value="xl">XL</option>
															<option value="xxl">XXL</option>
														</select>
													</div>
												</div>
												{this.state.restrictEdit || this.state.restrictMajorEdit ? (
													""
												) : (
													<div className="col padding">
														<input
															className="form-check-input"
															type="checkbox"
															id="shirt_ordered"
															onChange={this.handleInputChange}
															checked={
																this.state.values.shirt_ordered === "y"
															}
															disabled={this.state.restrictEdit}
														/>{" "}
														<label
															className="form-check-label"
															htmlFor="shirt_ordered"
														>
															Sent
														</label>
													</div>
												)}
											</div>
											{this.state.restrictMajorEdit ? (
												""
											) : (
												<>
													<div className="row">
														<div className="col">Additional Information</div>
													</div>
													<div className="row">
														<div className="col">
															<textarea
																type="text"
																className="form-control"
																id="additional_info"
																placeholder="Additional Information"
																onChange={this.handleInputChange}
																value={
																	this.state.values.additional_info
																}
																disabled={this.state.restrictEdit}
															/>
														</div>
													</div>
													<div className="row">
														<div className="col text-sm">
															{this.getChangedByMessage()}
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="text-right">
									{this.state.msg}{" "}
									{this.state.restrictEdit ? (
										""
									) : (
										<>
											<button
												className="btn bg-light-purple margin-top-bottom-lg"
												onClick={this.submitInformation}
												disabled={this.state.loading}
											>
												<strong>Save</strong>
											</button>{" "}
											{this.state.restrictEdit || this.state.restrictMajorEdit ? (
												""
											) : this.state.approved === "y" ? (
												""
											) : (
												<button
													type="button"
													className="btn bg-light-purple margin-top-bottom-lg"
													onClick={() => this.handleApprove()}
													disabled={this.state.loading}
												>
													<strong>Approve</strong>
												</button>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DancerProfile;
