import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export class Approval extends Component {
	state = {
		dancers: [],
		filtered: [],
		search: "",
		msg: "",
		loading: false,
	};

	async componentDidMount() {
		var approval;
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/unapproved", {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					approval = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({ dancers: approval, filtered: approval, loading: false });
	}

	inputHandler = (event) => {
		var text = event.target.value;
		this.setState({ search: text });
		if (text.length <= 1) {
			this.setState({ filtered: this.state.dancers });
		} else {
			this.setState({
				filtered: this.state.dancers.filter(
					(dancer) =>
						dancer.first_name.toLowerCase().includes(text) ||
						dancer.last_name.toLowerCase().includes(text)
				),
			});
		}
	};

	getDoB(dob) {
		return (
			dob.charAt(0) +
			dob.charAt(1) +
			"/" +
			dob.charAt(2) +
			dob.charAt(3) +
			"/" +
			dob.charAt(4) +
			dob.charAt(5) +
			dob.charAt(6) +
			dob.charAt(7)
		);
	}

	handleApprove = async (pupil_id) => {
		var body = { approved: "y" };
		var msg;
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + pupil_id, body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = response.data.first_name + " " + response.data.last_name + " Approved!";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg });
		this.componentDidMount();
	};

	render() {
		return (
			<div>
				<Navbar title="Approval List" />
				<div className="container padding-md justify-content-center align-items-center">
					<div className="row">
						<div className="col">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div
										className={
											window.innerWidth < 770
												? "col margin-bottom margin-left-sm margin-right-sm padding-0"
												: "col margin-bottom margin-left margin-right"
										}
									>
										<div>
											<input
												className="form-control form-control-lg bg-light-purple"
												type="text"
												placeholder="Search"
												onChange={this.inputHandler}
												value={this.state.search}
											></input>
										</div>
									</div>
									{window.innerWidth < 770 ? "" : <div className="col"></div>}
									<div
										className={
											window.innerWidth < 770
												? "col margin-bottom margin-left-sm margin-right-sm padding-0"
												: "col margin-bottom margin-left margin-right"
										}
									>
										{this.state.msg}
									</div>
								</div>
								<div className={window.innerWidth < 770 ? "row" : "row padding"}>
									<div className="col bg-purple">
										<div className="row">
											<div
												className={
													window.innerWidth < 770
														? "col text-break text-wrap padding-sm"
														: "col"
												}
											>
												<p
													className={
														window.innerWidth < 770
															? "h6 fw-bold"
															: "h4 fw-bold"
													}
												>
													First Name
												</p>
											</div>
											<div
												className={
													window.innerWidth < 770
														? "col text-break text-wrap padding-sm"
														: "col"
												}
											>
												<p
													className={
														window.innerWidth < 770
															? "h6 fw-bold"
															: "h4 fw-bold"
													}
												>
													Last Name
												</p>
											</div>
											<div
												className={
													window.innerWidth < 770
														? "col text-break text-wrap padding-sm"
														: "col"
												}
											>
												<p
													className={
														window.innerWidth < 770
															? "h6 fw-bold"
															: "h4 fw-bold"
													}
												>
													Date Of Birth
												</p>
											</div>
											<div
												className={
													window.innerWidth < 770
														? "col text-break text-wrap padding-sm"
														: "col"
												}
											>
												<p
													className={
														window.innerWidth < 770
															? "h6 fw-bold"
															: "h4 fw-bold"
													}
												>
													Additional Information
												</p>
											</div>
											{window.innerWidth < 770 ? (
												<></>
											) : (
												<div className="col">
													<h4>
														<strong> </strong>
													</h4>
												</div>
											)}
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.filtered.map((dancer, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-sm margin-right-sm"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
												onClick={() => {
													window.location.href =
														"/dancer/profile/" + dancer.pupil_id;
												}}
											>
												<div className="container margin-top-bottom padding-right-0 padding-left-0">
													<div className="row align-items-center padding-right-0 padding-left-0">
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>{dancer.first_name}</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>{dancer.last_name}</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>{this.getDoB(dancer.dob)}</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>
																{dancer.additional_info
																	? dancer.additional_info
																	: "None"}
															</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col-12 text-right text-break text-wrap padding-right-2 padding-left-2"
																	: "col text-right"
															}
														>
															{window.innerWidth < 770 ? (
																<></>
															) : (
																<Link
																	type="button"
																	className="btn btn-lg"
																	to={
																		"/dancer/profile/" +
																		dancer.pupil_id
																	}
																>
																	<i className="bi bi-pencil-fill"></i>
																</Link>
															)}
															<button
																type="button"
																className="btn btn-lg"
																onClick={() =>
																	this.handleApprove(dancer.pupil_id)
																}
															>
																{this.state.loading ? (
																	<div className="d-flex justify-content-center">
																		<div
																			className="spinner-border"
																			role="status"
																		>
																			<span className="sr-only"></span>
																		</div>
																	</div>
																) : (
																	<i className="bi bi-check-circle-fill"></i>
																)}
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Approval;
