import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export class Classes extends Component {
	state = {
		classes: [],
		values: { class_name: "", start_time: "", max_pupils: "" },
		error_msg: "",
		confirm_delete: false,
		errorMsg: "",
		loading: false,
	};

	async componentDidMount() {
		var classes;
		var msg = "";
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/classes", {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					classes = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ classes: classes, error_msg: msg, loading: false, values: { class_name: "", start_time: "", max_pupils: "" }, errorMsg: "", msg: "", confirm_delete: false });
	}

	submitValue = (event) => {
		event.preventDefault();
		this.setState({
			errorMsg: "",
		});
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (property === "max_pupils") {
				continue;
			}
			if (this.state.values[property] === "" || this.state.values[property] === " ") {
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
				});
				return;
			}
		}
		this.sendToBackend();
	};

	async sendToBackend() {
		this.setState({ loading: true });
		var msg;
		var body = {
			class_name: this.state.values.class_name,
			start_time: this.state.values.start_time,
			max_pupils: Number(this.state.values.max_pupils),
		};
		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/classes", body, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Class Created";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	deleteClass = async (id) => {
		this.setState({ loading: true });
		var msg;
		await axios
			.delete(process.env.REACT_APP_API_END_POINT + "/api/classes/" + id, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 204) {
					msg = "Class Deleted";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	};

	render() {
		return (
			<div>
				<Navbar title="Classes" />
				<div className={window.innerWidth < 770 ? "container padding-md text-center" : "container padding-md"}>
					<div className="row">
						<div className="col">
							<div className={window.innerWidth < 770 ? "container bg-purple rounded padding margin-bottom" : "container bg-purple rounded padding margin-bottom"}>
								<div className="row">
									<div className="col text-right">
										<h3 className="text-right">
											<i type="button" data-bs-toggle="modal" data-bs-target="#addItem" className="bi bi-plus-square bg-purple"></i>
										</h3>
										<div className="modal fade" id="addItem" tabIndex="-1" aria-labelledby="addItemLabel" aria-hidden="true">
											<div className="modal-dialog modal-lg">
												<div className="modal-content bg-purple">
													<div className="modal-header">
														<h5 className="modal-title" id="addItemLabel">
															Add New Class
														</h5>
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
															onClick={() => this.componentDidMount()}
														></button>
													</div>
													<div className="modal-body bg-light-purple margin rounded">
														<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
															<div className="container">
																<div className="row">
																	<div className="col padding text-center">
																		Here you can add a new class to the system. The class will be created unassigned.
																	</div>
																</div>
																<div className="row justify-content-center">
																	<div className="row">
																		<div className="col-12 col-md-12 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="class_name"
																						placeholder="class_name"
																						onChange={this.handleInputChange}
																						value={this.state.values.class_name}
																						required
																					/>
																					<label htmlFor="class_name" className="login-text-override">
																						Class Name
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="start_time"
																						placeholder="hashed_password"
																						onChange={this.handleInputChange}
																						value={this.state.values.start_time}
																						required
																					/>
																					<label htmlFor="start_time" className="login-text-override">
																						Start Time
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="max_pupils"
																						placeholder="max_pupils"
																						onChange={this.handleInputChange}
																						value={this.state.values.max_pupils}
																						required
																					/>
																					<label htmlFor="max_pupils" className="login-text-override">
																						Max Dancers
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col">{this.getError()}</div>
																	</div>
																	<div className="row">
																		<div className="col text-right">
																			{this.state.msg}{" "}
																			<button
																				className="btn bg-purple"
																				type="submit"
																				disabled={this.state.loading || this.state.msg === "Class Created"}
																			>
																				Submit
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className={window.innerWidth < 770 ? "col bg-purple padding-sm" : "col bg-purple margin"}>
										<div className={window.innerWidth < 770 ? "container g-0" : "container"}>
											<div className="row g-0">
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Name</p>
												</div>
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Teacher</p>
												</div>
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Time</p>
												</div>
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Numbers</p>
												</div>
												{window.innerWidth < 770 ? (
													""
												) : (
													<div className="col">
														<h4>
															<strong> </strong>
														</h4>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{!this.state.error_msg ? (
									this.state.classes.map((classObj, index) => {
										return (
											<div className="row" key={index}>
												<div
													className={
														window.innerWidth < 770
															? "col bg-light-purple rounded margin-bottom margin-left-sm margin-right-sm justify-content-center"
															: "col bg-light-purple rounded margin-bottom margin-left margin-right"
													}
													onClick={() => {
														if (window.innerWidth < 770) {
															window.location.href = "/class/info/" + classObj.class_id;
														}
													}}
												>
													<div className="container margin-top-bottom">
														<div className="row align-items-center">
															<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
																<strong>{classObj.class_name}</strong>
															</div>
															<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
																<strong>
																	{classObj.first_name === null && classObj.last_name === null
																		? "Unassigned"
																		: classObj.first_name + " " + classObj.last_name}
																</strong>
															</div>
															<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
																<strong>{classObj.start_time}</strong>
															</div>
															<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
																<strong>
																	{classObj.pupil_count} / {classObj.max_pupils}
																</strong>
															</div>
															{window.innerWidth < 770 ? (
																""
															) : (
																<div className="col text-right">
																	<button type="button" className="btn btn-lg" data-bs-toggle="modal" data-bs-target={"#deleteModal" + index}>
																		<i className="bi bi-trash-fill"></i>
																	</button>
																	<div
																		className="modal fade"
																		id={"deleteModal" + index}
																		tabindex="-1"
																		aria-labelledby="deleteModalLabel"
																		aria-hidden="true"
																	>
																		<div className="modal-dialog">
																			<div className="modal-content bg-purple">
																				<div className="modal-header">
																					<h1 className="modal-title fs-5" id="deleteModalLabel">
																						Deleting Class {classObj.class_name}
																					</h1>
																					<button
																						type="button"
																						className="btn-close"
																						data-bs-dismiss="modal"
																						aria-label="Close"
																						onClick={() => this.componentDidMount()}
																					></button>
																				</div>
																				<div className="modal-body text-start">
																					<div className="rounded padding bg-light-purple ">
																						You are about to delete {classObj.class_name} from the system. If there are any dancers in
																						this class, they will become unassigned. The teacher will also become unassigned. This
																						action is irreversible!
																						<br />
																						<input
																							className="form-check-input"
																							type="checkbox"
																							id="confirm_delete"
																							onChange={() => this.setState({ confirm_delete: !this.state.confirm_delete })}
																							checked={this.state.confirm_delete}
																							required
																						/>{" "}
																						<strong>Tick this box to confirm deletion</strong>
																					</div>
																				</div>
																				<div className="modal-footer">
																					{this.state.msg}
																					<button
																						type="button"
																						className="btn btn-danger"
																						onClick={() => this.deleteClass(classObj.class_id)}
																						disabled={this.state.msg || !this.state.confirm_delete}
																					>
																						Permanently Delete
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																	<Link type="button" className="btn btn-lg" to={"/class/info/" + classObj.class_id}>
																		<i className="bi bi-pencil-fill"></i>
																	</Link>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										);
									})
								) : (
									<div className="row">
										<div className="col bg-light-purple rounded margin-bottom margin-left margin-right">
											<div className="container margin-top-bottom">
												<div className="row align-items-center">
													<div className="col">{this.state.error_msg}</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Classes;
