import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export class UserProfile extends Component {
	state = {
		id: this.props.id,
		user_id: this.props.user_id,
		user_role: CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY).toString(
			CryptoJS.enc.Utf8
		),
		values: {
			first_name: "",
			last_name: "",
			email: "",
			contact_number: "",
			first_line: "",
			second_line: "",
			town: "",
			county: "",
			postcode: "",
			roles: [],
		},
		usersClasses: [],
		classes: [],
		dancers: [],
		msg: "",
		loading: false,
	};

	async componentDidMount() {
		this.setState({ loading: true });
		var user = {};
		var roles = [];
		var classesObj;
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/users/" + this.state.id, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					user = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		if (this.state.user_role === "Admin") {
			await axios
				.get(process.env.REACT_APP_API_END_POINT + "/api/roles/user/" + this.state.id, {
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						roles = response.data;
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						window.location.replace("/error");
					}
				});
			if (roles.includes("Teacher")) {
				var usersClasses;
				await axios
					.get(process.env.REACT_APP_API_END_POINT + "/api/classes", {
						headers: {
							Authorization:
								"Bearer " +
								CryptoJS.AES.decrypt(
									Cookies.get("token"),
									process.env.REACT_APP_SECRET_KEY
								).toString(CryptoJS.enc.Utf8),
						},
					})
					.then((response) => {
						if (response.status === 200) {
							classesObj = response.data;
						} else {
							window.location.replace("/error");
						}
					})
					.catch((error) => {
						if (error.response.status === 403) {
							window.location.replace("/forbidden");
						} else if (error.response.status === 404) {
							window.location.replace("/not-found");
						} else {
							window.location.replace("/error");
						}
					});
				await axios
					.get(process.env.REACT_APP_API_END_POINT + "/api/classes/user/" + this.state.id, {
						headers: {
							Authorization:
								"Bearer " +
								CryptoJS.AES.decrypt(
									Cookies.get("token"),
									process.env.REACT_APP_SECRET_KEY
								).toString(CryptoJS.enc.Utf8),
						},
					})
					.then((response) => {
						if (response.status === 200) {
							usersClasses = response.data;
						} else {
							window.location.replace("/error");
						}
					})
					.catch((error) => {
						if (error.response.status === 403) {
							window.location.replace("/forbidden");
						} else if (error.response.status === 404) {
							window.location.replace("/not-found");
						} else {
							window.location.replace("/error");
						}
					});
				classesObj.push({ class_id: null, class_name: "Unassigned" });
				this.setState({ usersClasses: usersClasses, classes: classesObj });
			}
			if (roles.includes("Parent")) {
				var parentDancers;
				await axios
					.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/user/" + this.state.id, {
						headers: {
							Authorization:
								"Bearer " +
								CryptoJS.AES.decrypt(
									Cookies.get("token"),
									process.env.REACT_APP_SECRET_KEY
								).toString(CryptoJS.enc.Utf8),
						},
					})
					.then((response) => {
						if (response.status === 200) {
							parentDancers = response.data;
						} else {
							window.location.replace("/error");
						}
					})
					.catch((error) => {
						if (error.response.status === 403) {
							window.location.replace("/forbidden");
						} else if (error.response.status === 404) {
							window.location.replace("/not-found");
						} else {
							window.location.replace("/error");
						}
					});
				this.setState({ dancers: parentDancers });
			}
			this.setState({ roles: roles });
		}
		this.setState({
			values: {
				first_name: user.first_name,
				last_name: user.last_name,
				email: user.email,
				contact_number: user.contact_number,
				first_line: user.first_line,
				second_line: user.second_line,
				town: user.town,
				county: user.county,
				postcode: user.postcode,
				roles: roles,
			},
			loading: false,
		});
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		var tempRoles = this.state.values.roles;

		if (name === "roles_admin") {
			if (tempRoles.includes("Admin")) {
				tempRoles.splice(tempRoles.indexOf("Admin"), 1);
			} else {
				tempRoles.push("Admin");
			}
			this.setState({ values: { ...this.state.values, roles: tempRoles } });
		} else if (name === "roles_teacher") {
			if (tempRoles.includes("Teacher")) {
				tempRoles.splice(tempRoles.indexOf("Teacher"), 1);
			} else {
				tempRoles.push("Teacher");
			}
			this.setState({ values: { ...this.state.values, roles: tempRoles } });
		} else if (name === "roles_parent") {
			if (tempRoles.includes("Parent")) {
				tempRoles.splice(tempRoles.indexOf("Parent"), 1);
			} else {
				tempRoles.push("Parent");
			}
			this.setState({ values: { ...this.state.values, roles: tempRoles } });
		} else {
			this.setState({ values: { ...this.state.values, [name]: value }, msg: "" });
		}
	};

	submitInformation = async () => {
		this.setState({ loading: true });
		var body = this.state.values;
		var msg = "";
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/users/" + this.state.id + "/update", body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Saved!";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		if (this.state.user_role === "Admin") {
			await axios
				.post(
					process.env.REACT_APP_API_END_POINT + "/api/roles/user/" + this.state.id,
					this.state.values.roles,
					{
						headers: {
							Authorization:
								"Bearer " +
								CryptoJS.AES.decrypt(
									Cookies.get("token"),
									process.env.REACT_APP_SECRET_KEY
								).toString(CryptoJS.enc.Utf8),
						},
					}
				)
				.then((response) => {
					if (response.status === 200) {
						msg = "Saved!";
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						msg = error.response.data;
					}
				});
		}
		this.setState({ msg: msg, loading: false });
		this.componentDidMount();
	};

	getAdminTab() {
		if (this.state.user_role.includes("Admin")) {
			return (
				<>
					<li className="nav-item">
						<button
							className="nav-link bg-purple"
							id="roles-tab"
							data-bs-toggle="tab"
							data-bs-target="#roles"
							role="tab"
							type="button"
							aria-controls="roles"
							aria-selected="false"
							href=""
						>
							<strong>
								{window.innerWidth < 770 ? "" : "Roles"}{" "}
								<i className="bi bi-diagram-2-fill"></i>
							</strong>
						</button>
					</li>
				</>
			);
		}
	}

	getTeacherTab() {
		if (this.state.user_role === "Admin") {
			if (this.state.values.roles.includes("Teacher")) {
				return (
					<>
						<li className="nav-item">
							<button
								className="nav-link bg-purple"
								id="class-tab"
								data-bs-toggle="tab"
								data-bs-target="#class"
								role="tab"
								type="button"
								aria-controls="class"
								aria-selected="false"
								href=""
							>
								<strong>
									{window.innerWidth < 770 ? "" : "Class"}{" "}
									<i className="bi bi-calendar-date-fill"></i>
								</strong>
							</button>
						</li>
					</>
				);
			}
		}
	}

	getParentTab() {
		if (this.state.user_role === "Admin") {
			if (this.state.values.roles.includes("Parent")) {
				return (
					<>
						<li className="nav-item">
							<button
								className="nav-link bg-purple"
								id="dancer-tab"
								data-bs-toggle="tab"
								data-bs-target="#dancer"
								role="tab"
								type="button"
								aria-controls="dancer"
								aria-selected="false"
								href=""
							>
								<strong>
									{window.innerWidth < 770 ? "" : "Dancers"}{" "}
									<i className="bi bi-music-note-beamed"></i>
								</strong>
							</button>
						</li>
					</>
				);
			}
		}
	}

	getAdminTabContent() {
		if (this.state.user_role.includes("Admin")) {
			const isAdmin = this.state.values.roles.includes("Admin");
			const isTeacher = this.state.values.roles.includes("Teacher");
			const isParent = this.state.values.roles.includes("Parent");
			return (
				<>
					<div className="tab-pane fade" id="roles" role="tabpanel" aria-labelledby="roles-tab">
						<div className="container padding">
							<div className="row">
								<div className="col padding">
									<input
										className="form-check-input danger"
										type="checkbox"
										id="roles_admin"
										onChange={this.handleInputChange}
										checked={isAdmin}
										title="WARNING! Adding this role will allow this user to do some dangerous stuff..."
									/>{" "}
									<strong>Admin</strong> - This will allow the user to perform all
									administrative actions (Be cautious).
								</div>
							</div>
							<div className="row">
								<div className="col padding">
									<input
										className="form-check-input danger"
										type="checkbox"
										id="roles_teacher"
										onChange={this.handleInputChange}
										checked={isTeacher}
									/>{" "}
									<strong>Teacher</strong> - This will allow the user to view classes that
									are assigned to them, as well as dancers in their class.
								</div>
							</div>
							<div className="row">
								<div className="col padding">
									<input
										className="form-check-input danger"
										type="checkbox"
										id="roles_parent"
										onChange={this.handleInputChange}
										checked={isParent}
									/>{" "}
									<strong>Parent</strong> - This role allows users to view their childrens
									data and onboard new children when a space becomes available.
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	}

	getTeacherTabContent() {
		if (this.state.user_role.includes("Admin")) {
			if (this.state.values.roles.includes("Teacher")) {
				return (
					<div className="tab-pane fade" id="class" role="tabpanel" aria-labelledby="class-tab">
						<div className="container padding">
							<div className="row">
								<div className="col">
									<ul className="list-group list-group-flush">
										<li className="bg-light-purple list-group-item">
											<div className="container">
												<div className="row">
													<div className="col">
														<strong>Class Name</strong>
													</div>
													<div className="col">
														<strong>Start Time</strong>
													</div>
													<div className="col">
														<strong> </strong>
													</div>
												</div>
											</div>
										</li>
										{this.state.usersClasses.length <= 0 ? (
											<li className="bg-light-purple list-group-item">
												<div className="col rounded">
													<div className="container margin-top-bottom">
														<div className="row align-items-center">
															<div className="col">
																No Classes Assigned
															</div>
															<div className="col">
																Click the pencil to assign a class
															</div>
															<div className="col text-right">
																<Link
																	type="button"
																	className="btn btn-lg"
																	to={"/classes"}
																>
																	<i className="bi bi-pencil-fill"></i>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</li>
										) : (
											this.state.usersClasses.map((usersClass, index) => {
												return (
													<li
														className="bg-light-purple list-group-item"
														key={index}
													>
														<div className="col rounded">
															<div className="container margin-top-bottom">
																<div className="row align-items-center">
																	<div className="col">
																		{usersClass.class_name}
																	</div>
																	<div className="col">
																		{usersClass.start_time}
																	</div>
																	<div className="col text-right">
																		<Link
																			type="button"
																			className="btn btn-lg"
																			to={
																				"/class/info/" +
																				usersClass.class_id
																			}
																		>
																			<i className="bi bi-pencil-fill"></i>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													</li>
												);
											})
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}

	getParentTabContent() {
		if (this.state.user_role.includes("Admin")) {
			if (this.state.values.roles.includes("Parent")) {
				return (
					<div className="tab-pane fade" id="dancer" role="tabpanel" aria-labelledby="dancer-tab">
						<div className="container padding">
							<div className="row">
								<div className="col">
									<ul className="list-group list-group-flush">
										<li className="bg-light-purple list-group-item padding-left-0 padding-right-0">
											<div className="container">
												<div className="row">
													<div className="col">
														<strong>First Name</strong>
													</div>
													<div className="col">
														<strong>Class</strong>
													</div>
													<div className="col">
														<strong> </strong>
													</div>
												</div>
											</div>
										</li>
										{this.state.dancers.length <= 0 ? (
											<li className="bg-light-purple list-group-item">
												<div className="col rounded">
													<div className="container margin-top-bottom">
														<div className="row align-items-center">
															<div className="col">
																This parent has no dancers
															</div>
														</div>
													</div>
												</div>
											</li>
										) : (
											this.state.dancers.map((dancer, index) => {
												return (
													<li
														className="bg-light-purple list-group-item padding-left-0 padding-right-0"
														key={index}
													>
														<div className="col rounded">
															<div className="container margin-top-bottom">
																<div className="row align-items-center">
																	<div className="col text-wrap">
																		{dancer.first_name}
																	</div>
																	<div className="col">
																		{dancer.class_name}
																	</div>
																	<div className="col text-right">
																		<Link
																			type="button"
																			className="btn btn-lg"
																			to={
																				"/dancer/profile/" +
																				dancer.pupil_id
																			}
																		>
																			<i className="bi bi-search"></i>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													</li>
												);
											})
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}

	getSaveButton() {
		var user_id = jwtDecode(
			CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
		).sub;
		if (this.state.user_role.includes("Admin") || user_id === this.state.id) {
			return (
				<div className="text-right">
					{this.state.msg}{" "}
					<button
						className="btn bg-light-purple"
						onClick={this.submitInformation}
						disabled={this.state.loading}
					>
						<strong>Save</strong>
					</button>
				</div>
			);
		}
	}

	render() {
		return (
			<div>
				<Navbar title="User Profile" />
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="bg-purple rounded padding margin">
								<div className="bg-light-purple rounded margin text-center">
									<div className="container padding">
										<div className="row">
											<div className="col margin">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="160"
													height="160"
													fill="currentColor"
													className="bi bi-person-circle"
													viewBox="0 0 16 16"
												>
													<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
													<path
														fillRule="evenodd"
														d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
													/>
												</svg>
											</div>
										</div>
										<div className="row">
											<div className="col">
												{this.state.loading ? (
													<div className="d-flex justify-content-center">
														<div className="spinner-border" role="status">
															<span className="sr-only"></span>
														</div>
													</div>
												) : (
													""
												)}
												<h5>
													<strong>
														{this.state.values.first_name}{" "}
														{this.state.values.last_name}
													</strong>
												</h5>
											</div>
										</div>
										{this.state.user_role === "Admin" ? (
											<div className="row">
												<div className="col">
													{this.state.values.roles.map((role, index) => {
														if (index < this.state.values.roles.length - 1) {
															return role + ", ";
														} else {
															return role;
														}
													})}
												</div>
											</div>
										) : (
											// Eventually might be nice to show some roles
											""
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="bg-purple rounded padding margin">
								<ul className="nav nav-tabs" id="myTab" role="tablist">
									<li className="nav-item">
										<button
											className="nav-link active bg-purple"
											id="personal-tab"
											data-bs-toggle="tab"
											data-bs-target="#personal"
											role="tab"
											type="button"
											aria-controls="personal"
											aria-selected="true"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Personal"}{" "}
												<i className="bi bi-grid-fill"></i>
											</strong>
										</button>
									</li>
									<li className="nav-item">
										<button
											className="nav-link bg-purple"
											id="contact-tab"
											data-bs-toggle="tab"
											data-bs-target="#contact"
											role="tab"
											type="button"
											aria-controls="contact"
											aria-selected="false"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Address"}{" "}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													fill="currentColor"
													className="bi bi-pin-map-fill"
													viewBox="0 0 16 16"
												>
													<path
														fill-rule="evenodd"
														d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8z"
													/>
													<path
														fill-rule="evenodd"
														d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"
													/>
												</svg>
											</strong>
										</button>
									</li>
									{this.getAdminTab()}
									{this.getTeacherTab()}
									{this.getParentTab()}
								</ul>
								<div
									className="tab-content bg-light-purple rounded-bottom margin-bottom"
									id="myTabContent"
								>
									<div
										className="tab-pane fade show active"
										id="personal"
										role="tabpanel"
										aria-labelledby="personal-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col">First Name</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="first_name"
															placeholder="First Name"
															onChange={this.handleInputChange}
															value={this.state.values.first_name}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Last Name</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="last_name"
															placeholder="Last Name"
															onChange={this.handleInputChange}
															value={this.state.values.last_name}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-6">
													<div className="row">
														<div className="col">Email</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<input
																	type="text"
																	className="form-control"
																	id="email"
																	placeholder="Email"
																	onChange={this.handleInputChange}
																	value={this.state.values.email}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="row">
														<div className="col">Phone Number</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<input
																	type="text"
																	className="form-control"
																	id="phone_number"
																	placeholder="Phone Number"
																	onChange={this.handleInputChange}
																	value={
																		this.state.values
																			.contact_number
																	}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="contact"
										role="tabpanel"
										aria-labelledby="contact-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col">Address Line 1</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="first_line"
															placeholder="Address"
															onChange={this.handleInputChange}
															value={this.state.values.first_line}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Address Line 2</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="second_line"
															placeholder=""
															onChange={this.handleInputChange}
															value={this.state.values.second_line}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Town</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="town"
															placeholder="Town"
															onChange={this.handleInputChange}
															value={this.state.values.town}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">County</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="county"
															placeholder="County"
															onChange={this.handleInputChange}
															value={this.state.values.county}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Postcode</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															id="postcode"
															placeholder="Postcode"
															onChange={this.handleInputChange}
															value={this.state.values.postcode}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									{this.getAdminTabContent()}
									{this.getTeacherTabContent()}
									{this.getParentTabContent()}
								</div>
								{this.getSaveButton()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UserProfile;
