import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export class ChangeLog extends Component {
	state = {
		dancers: [],
		filtered: [],
		showRead: false,
		dancersWithRead: [],
		filteredWithRead: [],
		dancersWithoutRead: [],
		filteredWithoutRead: [],
		search: "",
		msg: "",
		loading: false,
	};

	async componentDidMount() {
		var changelog;
		var changelogWithoutRead;
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/changelog/", {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data !== null) {
						changelog = response.data;
						changelogWithoutRead = changelog.filter((change) => change.acknowledged_by === null);
					}
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({
			dancers: changelogWithoutRead,
			filtered: changelogWithoutRead,
			dancersWithRead: changelog,
			filteredWithRead: changelog,
			showRead: false,
			dancersWithoutRead: changelogWithoutRead,
			filteredWithoutRead: changelogWithoutRead,
			loading: false,
		});
	}

	inputHandler = (event) => {
		var text = event.target.value;
		this.setState({ search: text });
		if (text.length <= 1) {
			this.setState({ filtered: this.state.dancers });
		} else {
			this.setState({
				filtered: this.state.dancers.filter(
					(dancer) =>
						dancer.first_name.toLowerCase().includes(text) ||
						dancer.last_name.toLowerCase().includes(text)
				),
			});
		}
	};

	handleApprove = async (changelog_item_id) => {
		var msg;
		var body;
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/changelog/" + changelog_item_id + "/update", body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Acknowledged";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg });
		this.componentDidMount();
	};

	handleShowRead = () => {
		if (!this.state.showRead) {
			this.setState({
				dancers: this.state.dancersWithRead,
				filtered: this.state.filteredWithRead,
				showRead: !this.state.showRead,
			});
		} else {
			this.setState({
				dancers: this.state.dancersWithoutRead,
				filtered: this.state.filteredWithoutRead,
				showRead: !this.state.showRead,
			});
		}
	};

	render() {
		return (
			<div>
				<Navbar title="Change Log" />
				<div className="container padding-md justify-content-center align-items-center text-wrap">
					<div className="row">
						<div className="col">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div
										className={
											window.innerWidth < 770
												? "col margin-bottom margin-left-sm margin-right-sm padding-0"
												: "col margin-bottom margin-left margin-right"
										}
									>
										<div>
											<input
												className="form-control form-control-lg bg-light-purple"
												type="text"
												placeholder="Search"
												onChange={this.inputHandler}
												value={this.state.search}
											></input>
										</div>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col-2 col-md-6 margin-bottom margin-left margin-right-sm padding-0 d-flex align-items-center justify-content-end"
												: "col-2 col-md-6 margin-bottom margin-left margin-right d-flex align-items-center justify-content-end"
										}
									>
										<button
											className="btn bg-light-purple"
											type="button"
											onClick={this.handleShowRead}
										>
											{this.state.showRead ? "Hide" : "Show"} Read
										</button>
									</div>
								</div>
								<div className={window.innerWidth < 770 ? "row" : "row padding"}>
									<div
										className={
											window.innerWidth < 770
												? "col text-break text-wrap padding-sm"
												: "col"
										}
									>
										<p className={window.innerWidth < 770 ? "h6 fw-bold" : "h4 fw-bold"}>
											Name
										</p>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col text-break text-wrap padding-sm"
												: "col"
										}
									>
										<p className={window.innerWidth < 770 ? "h6 fw-bold" : "h4 fw-bold"}>
											Parent
										</p>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col text-break text-wrap padding-sm"
												: "col"
										}
									>
										<p className={window.innerWidth < 770 ? "h6 fw-bold" : "h4 fw-bold"}>
											Date and Field
										</p>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col text-break text-wrap padding-sm"
												: "col"
										}
									>
										<p className={window.innerWidth < 770 ? "h6 fw-bold" : "h4 fw-bold"}>
											Changed from
										</p>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col text-break text-wrap padding-sm"
												: "col"
										}
									>
										<p className={window.innerWidth < 770 ? "h6 fw-bold" : "h4 fw-bold"}>
											Changed to
										</p>
									</div>
									{window.innerWidth < 770 ? (
										<></>
									) : (
										<div className="col">
											<p className="h4 fw-bold"></p>
										</div>
									)}
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.filtered.map((dancer, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-sm margin-right-sm"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
												onClick={() => {
													window.location.href =
														"/dancer/profile/" + dancer.pupil_id;
												}}
											>
												<div className="container margin-top-bottom padding-right-0 padding-left-0">
													<div className="row align-items-center padding-right-0 padding-left-0">
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>
																{dancer.first_name +
																	" " +
																	dancer.last_name}
															</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>
																{dancer.parent_first_name +
																	" " +
																	dancer.parent_last_name}
															</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>
																{dancer.created_at.split("T")[0]} (
																{dancer.field_changed})
															</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>
																{dancer.initial_value
																	? dancer.initial_value
																	: "empty"}
															</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col text-break text-wrap padding-right-2 padding-left-2"
																	: "col"
															}
														>
															<strong>
																{dancer.new_value
																	? dancer.new_value
																	: "empty"}
															</strong>
														</div>
														<div
															className={
																window.innerWidth < 770
																	? "col-12 text-right text-break text-wrap padding-right-2 padding-left-2"
																	: "col text-right"
															}
														>
															{window.innerWidth < 770 ? (
																<></>
															) : (
																<>
																	<Link
																		type="button"
																		className="btn btn-lg"
																		to={
																			"/dancer/profile/" +
																			dancer.pupil_id
																		}
																	>
																		<i className="bi bi-pencil-fill"></i>
																	</Link>
																	<br />
																</>
															)}
															{dancer.acknowledged_by === null ? (
																<button
																	type="button"
																	className="btn btn-lg"
																	onClick={() =>
																		this.handleApprove(
																			dancer.changelog_item_id
																		)
																	}
																>
																	<i className="bi bi-check-circle-fill"></i>
																</button>
															) : (
																"Acknowledged by " +
																dancer.acknowledged_by +
																" on " +
																dancer.updated_at.split("T")[0]
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ChangeLog;
