import React, { Component } from "react";
import Navbar from "./Common/Navbar";

export class Contact extends Component {
	render() {
		return (
			<div>
				<Navbar title="Contact" />
				<div className="container">
					<div className="row">
						<div className="col margin rounded bg-purple">
							<div className="container padding ">
								<div className="row">
									<div className="col text-right">
										<h3>Phoenix Management Team</h3>
									</div>
								</div>
								<div className="row">
									<div className="col rounded bg-light-purple">
										If you have a question or would like to raise a concern please speak
										to a member of the Management team at dance or email on{" "}
										<strong>
											phoenixamateurdancegroup
											{window.innerWidth < 770 ? <br /> : ""}
											@gmail.com
										</strong>
									</div>
								</div>
							</div>
						</div>
						<div className="col margin rounded bg-purple">
							<div className="container padding ">
								<div className="row">
									<div className="col text-right">
										<h3>Website Admin Team</h3>
									</div>
								</div>
								<div className="row">
									<div className="col rounded bg-light-purple">
										If you are experiencing issues with the website, have identified a bug
										or would like to provide feedback, please contact the Website Admin
										Team on <strong>it@phoenixamateurdancegroup.com</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col margin rounded bg-purple">
							<div className="container padding ">
								<div className="row">
									<div className="col text-right">
										<h3>Data Protection Statement</h3>
									</div>
								</div>
								<div className="row">
									<div className="col rounded bg-light-purple">
										Phoenix Amateur Dance Group and Cue One Productions (and any
										sub-contractors) will endeavour to ensure that all data is protected
										and governed by the UK GDPR legislation. In the future, a Privacy
										Policy will be available to be downloaded here but for now you can ask
										the management team for this. If you have any questions or concerns
										regarding how your data is stored, please contact the Website Admin
										Team.
										<br />
										You can view what rights you have on this and all websites{" "}
										<a href="https://www.gov.uk/data-protection">here</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;
