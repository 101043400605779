import React, { Component } from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";

export class DancerSearch extends Component {
	state = {
		dancers: [
			{
				id: -1,
				first_name: "first_name",
				last_name: "last_name",
				class: "class",
				parent_name: "parent_name",
			},
		],
		filteredDancers: [],
		search: "",
		loading: false,
	};

	async componentDidMount() {
		var dancers = [];
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils", {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					dancers = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({ dancers: dancers, loading: false });
	}

	inputHandler = (event) => {
		var text = event.target.value;
		this.setState({ search: text });
		if (text.length <= 1) {
			this.setState({ filteredDancers: [] });
		} else {
			this.setState({
				filteredDancers: this.state.dancers.filter(
					(dancer) =>
						dancer.first_name.toLowerCase().includes(text) ||
						dancer.last_name.toLowerCase().includes(text)
				),
			});
		}
	};

	getSearchDivClassName() {
		var name = "bg-white rounded padding position-absolute z-3 overflow-auto max-height-350";
		if (this.state.filteredDancers.length <= 0) {
			name = name + " d-none";
		}
		return name;
	}

	render() {
		return (
			<div className="container bg-purple rounded padding margin-bottom">
				<div className="row justify-content-end">
					<div className="col">
						<Link
							to="/dancers"
							className="link-offset-2 link-underline link-underline-opacity-0 text-white"
						>
							<h3 className="text-light-purple text-right">Dancer Search</h3>
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col position-relative">
						<div>
							<input
								className="form-control bg-light-purple form-control-lg"
								type="text"
								placeholder="Start typing..."
								onChange={this.inputHandler}
								value={this.state.search}
							></input>
						</div>
						<div className={this.getSearchDivClassName()}>
							<ul className="list-group list-group-flush bg-white">
								{this.state.loading ? (
									<li className="list-group-item bg-white">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</li>
								) : (
									this.state.filteredDancers.map((item, index) => (
										<li className="list-group-item bg-white" key={index}>
											<Link to={"/dancer/profile/" + item.pupil_id} className="btn">
												{item.first_name} {item.last_name}
											</Link>
										</li>
									))
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DancerSearch;
