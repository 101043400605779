import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export class Dancers extends Component {
	state = {
		dancers: [],
		filtered: [],
		search: "",
		values: {
			parent_first_name: "",
			parent_last_name: "",
			email: "",
			child_first_name: "",
			child_last_name: "",
			dob: "",
			school_year: "Nursery",
		},
		errorMsg: "",
		loading: false,
		msg: "",
		confirm_delete: false,
		school_years: ["Nursery", "Reception", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "Other"],
	};

	async componentDidMount() {
		var dancers = [];
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils", {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					dancers = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});

		this.setState({
			dancers: dancers,
			filtered: dancers,
			loading: false,
			values: {
				parent_first_name: "",
				parent_last_name: "",
				email: "",
				child_first_name: "",
				child_last_name: "",
				dob: "",
				school_year: "Nursery",
			},
			msg: "",
			confirm_delete: false,
		});
	}

	handleClickDelete = async (id) => {
		this.setState({ loading: true });
		var msg;
		await axios
			.delete(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + id, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				msg = response.data;
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});

		this.setState({ msg: msg, loading: false });
	};

	inputHandler = (event) => {
		var text = event.target.value;
		this.setState({ search: text });
		if (text.length <= 1) {
			this.setState({ filtered: this.state.dancers });
		} else {
			this.setState({
				filtered: this.state.dancers.filter((dancer) => dancer.first_name.toLowerCase().includes(text) || dancer.last_name.toLowerCase().includes(text)),
			});
		}
	};

	submitValue = (event) => {
		event.preventDefault();
		this.setState({
			errorMsg: "",
		});
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (this.state.values[property] === "" || this.state.values[property] === " ") {
				// log to server
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
				});
				return;
			}
		}
		this.sendToBackend();
	};

	async sendToBackend() {
		this.setState({ loading: true });
		var msg;
		var body = {
			parent_email: this.state.values.email,
			first_name: this.state.values.child_first_name,
			last_name: this.state.values.child_last_name,
			parent_first_name: this.state.values.parent_first_name,
			parent_last_name: this.state.values.parent_last_name,
			// I absolutely hate that this is the way to do it
			dob: this.state.values.dob.replace("-", "").replace("-", ""),
			school_year: this.state.values.school_year,
		};
		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/temp-pupils/create", body, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Email Sent";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		if (name === "dob") {
			let dob = value.charAt(8) + value.charAt(9) + value.charAt(5) + value.charAt(6) + value.charAt(0) + value.charAt(1) + value.charAt(2) + value.charAt(3);
			this.setState({ values: { ...this.state.values, [name]: dob } });
		} else {
			this.setState({ values: { ...this.state.values, [name]: value } });
		}
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	getDoB(dob) {
		return dob.charAt(4) + dob.charAt(5) + dob.charAt(6) + dob.charAt(7) + "-" + dob.charAt(2) + dob.charAt(3) + "-" + dob.charAt(0) + dob.charAt(1);
	}

	deleteDancer = async (id) => {
		this.setState({ loading: true });
		var msg;
		await axios
			.delete(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + id, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Dancer Deleted";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	};

	render() {
		return (
			<div>
				<Navbar title="Dancers" />
				<div className="container padding-md justify-content-center align-items-center text-wrap">
					<div className="row">
						<div className="col">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row ">
									<div className={window.innerWidth < 770 ? "col margin-bottom margin-left-sm margin-right-sm padding-0" : "col margin-bottom margin-left margin-right"}>
										<input
											className="form-control form-control-lg bg-light-purple"
											type="text"
											placeholder="Search"
											onChange={this.inputHandler}
											value={this.state.search}
										></input>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col-2 col-md-6 margin-bottom margin-left-sm margin-right-sm padding-0 d-flex align-items-center justify-content-end"
												: "col-2 col-md-6 margin-bottom margin-left margin-right d-flex align-items-center justify-content-end"
										}
									>
										<h3 className="text-right">
											<i
												type="button"
												className="bi bi-plus-square"
												onClick={() => {
													window.location.href = "/onboarding/dashboard";
												}}
											></i>
										</h3>
									</div>
								</div>
								<div className="row">
									<div className={window.innerWidth < 770 ? "col bg-purple" : "col bg-purple margin"}>
										<div className="row">
											<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
												<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>First Name</p>
											</div>
											<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
												<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Last Name</p>
											</div>
											<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
												<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Class</p>
											</div>
											<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
												<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Parent</p>
											</div>
											{window.innerWidth < 770 ? (
												""
											) : (
												<div className="col">
													<h4>
														<strong> </strong>
													</h4>
												</div>
											)}
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.filtered.map((dancer, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-sm margin-right-sm"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
												onClick={() => {
													if (window.innerWidth < 770) {
														window.location.href = "/dancer/profile/" + dancer.pupil_id;
													}
												}}
											>
												<div className="container margin-top-bottom padding-right-0 padding-left-0">
													<div className="row align-items-center padding-right-0 padding-left-0">
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{dancer.first_name}</strong> {dancer.photo_consent === "n" ? <i className="bi bi-camera-fill bg-red"></i> : ""}{" "}
															{dancer.medical_information === "" || dancer.medical_information === null ? (
																""
															) : (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="16"
																	height="16"
																	fill="currentColor"
																	className="bi bi-bandaid-fill bg-red"
																	viewBox="0 0 16 16"
																>
																	<path d="m2.68 7.676 6.49-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Zm5.71-2.858a.5.5 0 1 0-.708.707.5.5 0 0 0 .707-.707ZM6.974 6.939a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707M5.56 8.354a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708m2.828 2.828a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707m1.414-2.121a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707m1.414-.707a.5.5 0 1 0-.706-.708.5.5 0 0 0 .707.708Zm-4.242.707a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707m1.414-.707a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708m1.414-2.122a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707M8.646 3.354l4 4 .708-.708-4-4zm-1.292 9.292-4-4-.708.708 4 4z" />
																</svg>
															)}{" "}
															{dancer.additional_info === "" || dancer.additional_info === null ? "" : <i className="bi bi-info-circle-fill bg-red"></i>}
														</div>
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{dancer.last_name}</strong>
														</div>
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{dancer.class_name ? dancer.class_name : "Unassigned"}</strong>
														</div>
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{dancer.parent_first_name + " " + dancer.parent_last_name}</strong>
														</div>
														{window.innerWidth < 770 ? (
															""
														) : (
															<div className="col text-right">
																<button type="button" className="btn btn-lg" data-bs-toggle="modal" data-bs-target={"#deleteModal" + index}>
																	<i className="bi bi-trash-fill"></i>
																</button>
																<div
																	className="modal fade"
																	id={"deleteModal" + index}
																	tabindex="-1"
																	aria-labelledby="deleteModalLabel"
																	aria-hidden="true"
																	data-bs-backdrop="static"
																>
																	<div className="modal-dialog">
																		<div className="modal-content bg-purple">
																			<div className="modal-header">
																				<h1 className="modal-title fs-5" id="deleteModalLabel">
																					Deleting Dancer {dancer.first_name + " " + dancer.last_name}
																				</h1>
																				<button
																					type="button"
																					className="btn-close"
																					data-bs-dismiss="modal"
																					aria-label="Close"
																					onClick={() => this.componentDidMount()}
																				></button>
																			</div>
																			<div className="modal-body text-start">
																				<div className="rounded padding bg-light-purple">
																					You are about to delete {dancer.first_name} from the system. All records associated with this
																					dancer will be deleted. Their parent will not be deleted and will remain on the system. This
																					action is irreversible!
																					<br />
																					<input
																						className="form-check-input"
																						type="checkbox"
																						id="confirm_delete"
																						onChange={() => this.setState({ confirm_delete: !this.state.confirm_delete })}
																						checked={this.state.confirm_delete}
																						required
																					/>{" "}
																					<strong>Tick this box to confirm deletion</strong>
																				</div>
																			</div>
																			<div className="modal-footer">
																				{this.state.msg}
																				<button
																					type="button"
																					className="btn btn-danger"
																					onClick={() => this.deleteDancer(dancer.pupil_id)}
																					disabled={this.state.msg || !this.state.confirm_delete}
																				>
																					Permanently Delete
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
																<Link type="button" className="btn btn-lg" to={"/dancer/profile/" + dancer.pupil_id}>
																	<i className="bi bi-pencil-fill"></i>
																</Link>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Dancers;
