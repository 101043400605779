import React from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

class Navbar extends React.Component {
	state = {
		title: this.props.title,
		role: CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
	};

	render() {
		return (
			<div>
				<nav className="navbar navbar-light bg-purple d-flex justify-content-center">
					<div className="row w-100">
						<div className="col d-flex justify-content-start align-items-center">
							<button
								className="btn float-end"
								data-bs-toggle="offcanvas"
								data-bs-target="#offcanvas"
							>
								<i
									className="bi bi-list text-white fs-3"
									data-bs-toggle="offcanvas"
									data-bs-target="#offcanvas"
								></i>
							</button>
						</div>
						<div className="col d-flex justify-content-center text-center align-items-center">
							<p className={window.innerWidth < 770 ? "h4" : "h1"}>
								<strong>{this.state.title}</strong>
							</p>
						</div>
						<div className="col d-flex justify-content-end  text-center align-items-center">
							<p className="h6">{this.state.role}</p>
						</div>
					</div>
				</nav>
				<div
					className="offcanvas offcanvas-start"
					tabIndex="-1"
					id="offcanvas"
					data-bs-keyboard="false"
					data-bs-backdrop="false"
				>
					<div className="offcanvas-header">
						<h4 className="offcanvas-title text-purple" id="offcanvas">
							Phoenix Admin System
						</h4>
						<button
							type="button"
							className="btn-close text-reset"
							data-bs-dismiss="offcanvas"
							aria-label="Close"
						></button>
					</div>
					<div className="offcanvas-body px-0">
						<ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
							<li className="nav-item">
								<Link to="/" className="nav-link text-truncate">
									<i className="fs-5 bi-speedometer2 text-purple"></i>
									<span className="ms-1 text-purple">
										<strong>Dashboard</strong>
									</span>
								</Link>
							</li>
							{this.state.role === "Admin" ? (
								<>
									<li className="nav-item">
										<Link to="/dancers" className="nav-link text-truncate">
											<i className="fs-5 bi-music-note-beamed text-purple"></i>
											<span className="ms-1 text-purple">
												<strong>Dancers</strong>
											</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/users" className="nav-link text-truncate">
											<i className="fs-5 bi-people text-purple"></i>
											<span className="ms-1 text-purple">
												<strong>Users</strong>
											</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/classes" className="nav-link text-truncate">
											<i className="fs-5 bi-calendar-date text-purple"></i>
											<span className="ms-1 text-purple">
												<strong>Classes</strong>
											</span>
										</Link>
									</li>

									<li className="nav-item">
										<Link to="/change-log" className="nav-link text-truncate">
											<i className="fs-5 bi-arrow-repeat text-purple"></i>
											<span className="ms-1 text-purple">
												<strong>Change Log</strong>
											</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/approval" className="nav-link text-truncate">
											<i className="fs-5 bi-check-circle text-purple"></i>
											<span className="ms-1 text-purple">
												<strong>Approval List</strong>
											</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/onboarding/dashboard" className="nav-link text-truncate">
											<i className="fs-5 bi-person-plus text-purple"></i>
											<span className="ms-1 text-purple">
												<strong>Onboarding</strong>
											</span>
										</Link>
									</li>
								</>
							) : (
								""
							)}
							<li className="nav-item">
								<Link to="/contact" className="nav-link text-truncate">
									<i className="fs-5 bi-telephone text-purple"></i>
									<span className="ms-1 text-purple">
										<strong>Contact</strong>
									</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to={
										"/user/profile/" +
										jwtDecode(
											CryptoJS.AES.decrypt(
												Cookies.get("token"),
												process.env.REACT_APP_SECRET_KEY
											).toString(CryptoJS.enc.Utf8)
										).sub
									}
									className="nav-link text-truncate"
								>
									<i className="fs-5 bi-person-circle text-purple"></i>
									<span className="ms-1 text-purple">
										<strong>My Account</strong>
									</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/logout" className="nav-link text-truncate">
									<i className="fs-5 bi-power text-purple"></i>
									<span className="ms-1 text-purple">
										<strong>Logout</strong>
									</span>
								</Link>
							</li>
						</ul>
					</div>
					<div className="d-flex align-items-end padding">
						Version 1.2.0
						<br />
						Phoenix Amateur Dance Group - Powered by Cue One IT
					</div>
				</div>
			</div>
		);
	}
}

export default Navbar;
