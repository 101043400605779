import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route, useParams, useSearchParams, Link } from "react-router-dom";
import Login from "./components/Login";
import Admin from "./components/AdminDashboard/Admin";
import Users from "./components/Users/Users";
import UserProfile from "./components/Users/UserProfile";
import Classes from "./components/Classes/Classes";
import ClassInfo from "./components/Classes/ClassInfo";
import Dancers from "./components/Dancers/Dancers";
import DancerProfile from "./components/Dancers/DancerProfile";
import Contact from "./components/Contact";
import Parent from "./components/ParentDashboard/Parent";
import Teacher from "./components/TeacherDashboard/Teacher";
import ChangeLog from "./components/AdminDashboard/ChangeLog";
import Onboarding from "./components/Onboarding/Onboarding";
import Cookies from "js-cookie";
import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Approval from "./components/AdminDashboard/Approval";
import PasswordRecovery from "./components/PasswordRecovery";
import OnboardingDashboard from "./components/Onboarding/Management/OnboardingDashboard";

function App() {
	const [token, setToken] = useState("");
	const [role, setRole] = useState("");

	if (!token && Cookies.get("token"))
		setToken(
			CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
		);
	if (!role && Cookies.get("role"))
		setRole(
			CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
		);

	return (
		/*<DownForMaintenance />*/

		<Router>
			<Routes>
				<Route
					exact
					path="/contact"
					element={token && role ? <Contact /> : <Login setToken={setToken} setRole={setRole} />}
				/>
				<Route
					path="/onboarding"
					element={<OnbaordingWithCodeAndID setToken={setToken} setRole={setRole} />}
				/>
				<Route
					exact
					path="/users"
					element={token && role ? <Users /> : <Login setToken={setToken} setRole={setRole} />}
				/>
				<Route exact path="/user/profile/:id" element={<UserProfileWithID />} />
				<Route
					exact
					path="/classes"
					element={token && role ? <Classes /> : <Login setToken={setToken} setRole={setRole} />}
				/>
				<Route exact path="/class/info/:id" element={<ClassInfoWithID />} />
				<Route
					exact
					path="/dancers"
					element={token && role ? <Dancers /> : <Login setToken={setToken} setRole={setRole} />}
				/>
				<Route exact path="/dancer/profile/:id" element={<DancerProfileWithID />} />
				<Route
					exact
					path="/change-log"
					element={
						token && role && role === "Admin" ? (
							<ChangeLog />
						) : (
							<Login setToken={setToken} setRole={setRole} />
						)
					}
				/>
				<Route
					exact
					path="/approval"
					element={
						token && role && role === "Admin" ? (
							<Approval />
						) : (
							<Login setToken={setToken} setRole={setRole} />
						)
					}
				/>
				<Route
					exact
					path="/onboarding/dashboard"
					element={
						token && role && role === "Admin" ? (
							<OnboardingDashboard />
						) : (
							<Login setToken={setToken} setRole={setRole} />
						)
					}
				/>
				<Route exact path="/logout" element={<Logout setToken={setToken} setRole={setRole} />} />
				<Route
					exact
					path="/login"
					element={
						token && role ? (
							<GetDashboard role={role} token={token} />
						) : (
							<Login setToken={setToken} setRole={setRole} />
						)
					}
				/>
				<Route
					exact
					path="/"
					element={
						token && role ? (
							<GetDashboard role={role} />
						) : (
							<Login setToken={setToken} setRole={setRole} />
						)
					}
				/>
				<Route exact path="/password-reset" element={<PasswordRecoveryWithTokenAndID />} />
				<Route exact path="/forbidden" element={<FourOhThreePage />} />
				<Route exact path="/error" element={<GenericErrorPage />} />
				<Route exact path="/not-found" element={<FourOhFourPage />} />
				<Route exact path="/success" element={<Success />} />
				<Route exact path="*" element={<FourOhFourPage />} />
			</Routes>
		</Router>
	);
}

function DancerProfileWithID() {
	let { id } = useParams();
	return <DancerProfile id={id} />;
}

function UserProfileWithID() {
	let { id } = useParams();
	return <UserProfile id={id} />;
}

function ClassInfoWithID() {
	let { id } = useParams();
	return <ClassInfo id={id} />;
}

function PasswordRecoveryWithTokenAndID() {
	const [searchParams] = useSearchParams();
	if (searchParams.get("token") && searchParams.get("id")) {
		return <PasswordRecovery token={searchParams.get("token")} id={searchParams.get("id")} />;
	} else {
		return <FourOhThreePage />;
	}
}

function OnbaordingWithCodeAndID({ setRole, setToken }) {
	const [searchParams] = useSearchParams();
	if (searchParams.get("code") && searchParams.get("id")) {
		return (
			<Onboarding
				code={searchParams.get("code")}
				id={searchParams.get("id")}
				setToken={setToken}
				setRole={setRole}
			/>
		);
	} else {
		return <FourOhFourPage />;
	}
}

function GetDashboard({ role }) {
	switch (role) {
		case "Admin":
			return <Admin />;
		case "Teacher":
			return <Teacher />;
		case "Parent":
			return <Parent />;
		default:
			return <FourOhFourPage />;
	}
}

function FourOhFourPage() {
	return (
		<div className="text-center">
			<h2>404 Page Not Found</h2>
			<br />
			<h5>You seemed to have stumbled on something that hasn't been build yet...</h5>
		</div>
	);
}

function FourOhThreePage() {
	return (
		<div className="text-center">
			<h2>403 Forbidden</h2>
			<br />
			<h5>
				It seems you don't have access to this. If you believe this is a mistake, please contact the
				administrator.
			</h5>
		</div>
	);
}

function Success() {
	return (
		<div className="text-center">
			<h2>Form Submitted Successfully</h2>
			<br />
			<h5>
				A member of the Management team will now review your information. If there are any issues, they will
				be in touch.
			</h5>
			Click <Link to="/">here</Link> to go to the dashboard.
		</div>
	);
}

// function DownForMaintenance() {
// 	return (
// 		<div className="text-center">
// 			<h2>Down For Maintenance</h2>
// 			<br />
// 			<h5>The site is currently down for maintenance. Sorry for any inconvenience.</h5>
// 		</div>
// 	);
// }

function GenericErrorPage() {
	return (
		<div className="text-center">
			<h2>Oops! Something went wrong.</h2>
			<br />
			<h5>The site had a hiccup... Please try again later.</h5>
		</div>
	);
}

function Logout(props) {
	Cookies.remove("token");
	Cookies.remove("role");
	props.setRole("");
	props.setToken("");
	return (
		<div className="text-center">
			<h2>You've been logged out</h2>
			<br />
			<h5>
				Click <Link to={"/login"}>here</Link> to log back in
			</h5>
		</div>
	);
}

export default App;
