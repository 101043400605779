import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Common/Navbar";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export class Teacher extends Component {
	state = {
		name: "",
		classes: [],
		dancers: [],
		photo_dancers: [],
		filteredDancers: [],
		search: "",
		msg: "",
		loading: false,
	};

	async componentDidMount() {
		this.setState({ loading: true });
		var user = {};
		var classes = [];
		var pupils = [];
		var userId = jwtDecode(
			CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
		).sub;
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/users/" + userId, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					user = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/classes/user/" + userId, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					classes = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});

		for (let classObj in classes) {
			let temp_pupils = [];
			await axios
				.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/class/" + classes[classObj].class_id, {
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						temp_pupils = temp_pupils.concat(response.data);
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						window.location.replace("/error");
					}
				});
			classes[classObj].current_num = temp_pupils.length;
			pupils = temp_pupils;
		}
		this.setState({
			name: user.first_name,
			classes: classes,
			dancers: pupils,
			photo_dancers: this.state.dancers.filter((dancer) => dancer.photo_consent === "n"),
			loading: false,
		});
	}

	inputHandler = (event) => {
		var text = event.target.value;
		this.setState({ search: text });
		if (text.length <= 1) {
			this.setState({ filteredDancers: [] });
		} else {
			this.setState({
				filteredDancers: this.state.dancers.filter(
					(dancer) =>
						dancer.first_name.toLowerCase().includes(text) ||
						dancer.last_name.toLowerCase().includes(text)
				),
			});
		}
	};

	getSearchDivClassName() {
		var name = "bg-white rounded padding position-absolute z-3 overflow-auto max-height-350";
		if (this.state.filteredDancers.length <= 0) {
			name = name + " d-none";
		}
		return name;
	}

	render() {
		return (
			<div>
				<Navbar title="Teacher Dashboard" />
				<div className="container rounded padding">
					<div className="row justify-content-center align-items-center">
						<div className="col-12 col-md-8">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div className="col">
										<h3 className="text-light-purple text-right">
											Welcome {this.state.name}!
										</h3>
									</div>
								</div>
								<div className="row">
									<div className="col bg-light-purple rounded margin-left margin-right">
										<p className="margin-top">
											Welcome to the Phoenix Amateur Dance Group Administration Site
											Teacher Dashboard. From here you can complete a variety of
											actions such as view dancer profiles and check classes.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center align-items-center">
						<div className="col-md-4 col-12">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div className="col">
										<h3 className="text-light-purple text-right">Photo Consent</h3>
									</div>
								</div>
								<div className="row">
									<div className="col rounded">
										<ul className="nav nav-tabs" id="myTab" role="tablist">
											<li className="nav-item">
												<button
													className="nav-link active bg-purple"
													id="photo-tab"
													data-bs-toggle="tab"
													data-bs-target="#photo"
													role="tab"
													type="button"
													aria-controls="photo"
													aria-selected="true"
												>
													<strong>
														{window.innerWidth < 770 ? "" : "Photo Consent"}{" "}
														<i className="bi bi-camera-fill"></i>
													</strong>
												</button>
											</li>
										</ul>

										<div
											className="tab-content bg-light-purple rounded-bottom margin-bottom"
											id="myTabContent"
										>
											<div
												className="tab-pane fade show active"
												id="photo"
												role="tabpanel"
												aria-labelledby="photo-tab"
											>
												<div className="container padding">
													<div className="row">
														<div className="col">
															The following dancers are not consented for
															photography:
														</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="list-group list-group-flush">
																{this.state.photo_dancers
																	? "No Dancers Found"
																	: this.state.photo_dancers.map(
																			(photo_dancer) => {
																				return (
																					<Link
																						to={
																							"/dancer/profile/" +
																							photo_dancer.pupil_id
																						}
																						className="list-group-item list-group-item-action link-underline link-underline-opacity-0 text-purple"
																						type="button"
																					>
																						{photo_dancer.first_name +
																							" in " +
																							photo_dancer.class_name}
																					</Link>
																				);
																			}
																	  )}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-12">
							<div className="container bg-purple rounded padding margin-bottom h-100 d-inline-block">
								<div className="row justify-content-end">
									<div className="col">
										<h3 className="text-light-purple text-right">Dancer Search</h3>
									</div>
								</div>
								<div className="row">
									<div className="col position-relative">
										<div>
											<input
												className="form-control bg-light-purple form-control-lg"
												type="text"
												placeholder="Start typing..."
												onChange={this.inputHandler}
												value={this.state.search}
											></input>
										</div>
										<div className={this.getSearchDivClassName()}>
											<ul className="list-group list-group-flush bg-white">
												{this.state.loading ? (
													<li className="list-group-item bg-white">
														<div className="spinner-border" role="status">
															<span className="sr-only"></span>
														</div>
													</li>
												) : (
													this.state.filteredDancers.map((item, index) => (
														<li
															className="list-group-item bg-white"
															key={index}
														>
															<Link
																to={"/dancer/profile/" + item.pupil_id}
																className="btn"
															>
																{item.first_name} {item.last_name}
															</Link>
														</li>
													))
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center align-items-center">
						<div className="col-12 col-md-8">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row justify-content-end">
									<div className="col">
										<h3 className="text-light-purple text-right">My Classes</h3>
									</div>
								</div>
								<div className={window.innerWidth < 770 ? "row" : "row padding"}>
									<div className="col bg-purple">
										<div className="row">
											<div className="col">
												<h4>
													<strong>Name</strong>
												</h4>
											</div>
											<div className="col">
												<h4>
													<strong>Time</strong>
												</h4>
											</div>
											<div className="col">
												<h4>
													<strong>Numbers</strong>
												</h4>
											</div>
											<div className="col">
												<h4>
													<strong> </strong>
												</h4>
											</div>
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.classes.map((classObj, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-2 margin-right-2"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
												onClick={() => {
													window.location.href =
														"/class/info/" + classObj.class_id;
												}}
											>
												<div className="container margin-top-bottom">
													<div className="row align-items-center">
														<div className="col">
															<strong>{classObj.class_name}</strong>
														</div>
														<div className="col">
															<strong>{classObj.start_time}</strong>
														</div>
														<div className="col">
															<strong>
																{classObj.current_num}
																{" / "}
																{classObj.max_pupils}
															</strong>
														</div>
														<div className="col text-right">
															<Link
																type="button"
																className="btn btn-lg"
																to={"/class/info/" + classObj.class_id}
																disabled
															>
																<i className="bi bi-pencil-fill"></i>
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Teacher;
