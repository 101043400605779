import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export class ClassInfo extends Component {
	state = {
		id: this.props.id,
		dancers: [],
		values: {
			name: "",
			time: "",
			teacher: "",
			max_num: "",
			classes: "",
			new_class: "",
			teacher_id: "0",
		},
		transfer: {},
		classes: [],
		numbers: "",
		teachers: [],
		msg: "",
		loading: false,
		showTransfer: false,
		restrictEdit: !CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY)
			.toString(CryptoJS.enc.Utf8)
			.includes("Admin"),
	};

	async componentDidMount() {
		var classObj = {
			class_name: "",
			max_pupils: "",
			start_time: "",
			class_id: "0",
			user_id: "0",
			first_name: "",
			last_name: "",
		};
		var pupils = [];
		var teachers = [];
		var classes = [];
		var msg;
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/classes/" + this.state.id, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					classObj = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});

		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/class/" + this.state.id, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					pupils = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		if (
			CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY)
				.toString(CryptoJS.enc.Utf8)
				.includes("Admin")
		) {
			await axios
				.get(process.env.REACT_APP_API_END_POINT + "/api/classes", {
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						classes = response.data;
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						msg = error.response.data;
					}
				});
			await axios
				.get(process.env.REACT_APP_API_END_POINT + "/api/users/roles/Teacher", {
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						teachers = response.data;
					} else {
						window.location.replace("/error");
					}
				})
				.catch((error) => {
					if (error.response.status === 403) {
						window.location.replace("/forbidden");
					} else if (error.response.status === 404) {
						window.location.replace("/not-found");
					} else {
						msg = error.response.data;
					}
				});
			classes.push({ class_id: 0, class_name: "Unassigned" });
			teachers.push({ user_id: 0, first_name: "Unassigned", last_name: "" });
			this.setState({
				classes: classes,
				showTransfer: true,
				values: { ...this.state.values, new_class: classes[0].class_id },
				teachers: teachers,
			});
		}
		var teacher_name = "";
		if (classObj.first_name == null && classObj.last_name == null) {
			teacher_name = "Unassigned";
		} else {
			teacher_name = classObj.first_name + " " + classObj.last_name;
		}

		this.setState({
			dancers: pupils,
			values: {
				name: classObj.class_name,
				time: classObj.start_time,
				teacher_name: teacher_name,
				teacher_id: classObj.user_id == null ? 0 : classObj.user_id,
				max_num: classObj.max_pupils,
			},
			numbers: pupils.length,
			loading: false,
			msg: msg,
		});
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value }, msg: "" });
	};

	handleTransferClick = async (pupil_id) => {
		if (
			!CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY)
				.toString(CryptoJS.enc.Utf8)
				.includes("Admin")
		) {
			return;
		}
		var msg = "Transferring...";
		this.setState({ msg: msg, loading: true });
		var pupil;
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + pupil_id, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					pupil = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		pupil.class_id = this.state.values.new_class === "0" ? null : Number(this.state.values.new_class);
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/pupils/" + pupil_id, pupil, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Successfully Transfered!";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	};

	async resetData() {
		this.setState({ loading: true });
		var pupils = [];
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/pupils/class/" + this.state.id, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					pupils = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({
			dancers: pupils,
			numbers: pupils.length,
			loading: false,
			msg: "",
			transfer: {},
		});
	}

	submitInformation = async () => {
		this.setState({ loading: true });
		let id = null;
		this.state.values.teacher_id === "0" ? (id = null) : (id = Number(this.state.values.teacher_id));
		var body = {
			class_name: this.state.values.name,
			max_pupils: Number(this.state.values.max_num),
			start_time: this.state.values.time,
			user_id: id,
		};
		var msg = "";
		await axios
			.put(process.env.REACT_APP_API_END_POINT + "/api/classes/" + this.state.id, body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "Saved!";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
		this.componentDidMount();
	};

	getDoB(dob) {
		return (
			dob.charAt(0) +
			dob.charAt(1) +
			"/" +
			dob.charAt(2) +
			dob.charAt(3) +
			"/" +
			dob.charAt(4) +
			dob.charAt(5) +
			dob.charAt(6) +
			dob.charAt(7)
		);
	}

	render() {
		return (
			<div>
				<Navbar title="Class Information" />
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="bg-purple rounded padding margin">
								<div className="bg-light-purple rounded margin text-center">
									<div className="container padding">
										<div className="row">
											<div className="col margin">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="100"
													height="100"
													fill="currentColor"
													className="bi bi-calendar-date"
													viewBox="0 0 16 16"
												>
													<path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
													<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
												</svg>
											</div>
										</div>
										<div className="row">
											<div className="col">
												{this.state.loading ? (
													<div className="d-flex justify-content-center">
														<div className="spinner-border" role="status">
															<span className="sr-only"></span>
														</div>
													</div>
												) : (
													""
												)}
												<h3>
													<strong>{this.state.values.name}</strong>
												</h3>
											</div>
										</div>
										<div className="row">
											<div className="col">{this.state.values.teacher_name}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="bg-purple rounded padding margin">
								<ul className="nav nav-tabs" id="myTab" role="tablist">
									<li className="nav-item">
										<button
											className="nav-link active bg-purple"
											id="general-tab"
											data-bs-toggle="tab"
											data-bs-target="#general"
											role="tab"
											type="button"
											aria-controls="general"
											aria-selected="true"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "General"}{" "}
												<i className="bi bi-grid-fill"></i>
											</strong>
										</button>
									</li>
									<li className="nav-item">
										<button
											className="nav-link bg-purple"
											id="dancers-tab"
											data-bs-toggle="tab"
											data-bs-target="#dancers"
											role="tab"
											type="button"
											aria-controls="dancers"
											aria-selected="false"
										>
											<strong>
												{window.innerWidth < 770 ? "" : "Dancers"}{" "}
												<i className="bi bi-people-fill"></i>
											</strong>
										</button>
									</li>
								</ul>
								<div
									className="tab-content bg-light-purple rounded-bottom margin-bottom"
									id="myTabContent"
								>
									<div
										className="tab-pane fade show active"
										id="general"
										role="tabpanel"
										aria-labelledby="general-tab"
									>
										<div className="container padding">
											<div className="row">
												<div className="col">Class Name</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															value={this.state.values.name}
															aria-label="classname"
															id="name"
															onChange={this.handleInputChange}
															disabled={this.state.restrictEdit}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Teacher</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														{this.state.restrictEdit ? (
															<>
																<input
																	type="text"
																	className="form-control"
																	value={
																		this.state.values.teacher_name
																	}
																	aria-label="teacher name"
																	id="teacher"
																	disabled
																/>
															</>
														) : (
															<>
																<select
																	className="form-control"
																	aria-label="teacher_id"
																	id="teacher_id"
																	onChange={this.handleInputChange}
																	value={
																		this.state.values.teacher_id
																	}
																>
																	{this.state.teachers.map(
																		(teacher, index) => {
																			return (
																				<option
																					key={index}
																					value={
																						teacher.user_id
																					}
																				>
																					{teacher.first_name +
																						" " +
																						teacher.last_name}
																				</option>
																			);
																		}
																	)}
																</select>
															</>
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">Time</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="input-group mb-3">
														<input
															type="text"
															className="form-control"
															value={this.state.values.time}
															aria-label="time"
															id="time"
															onChange={this.handleInputChange}
															disabled={this.state.restrictEdit}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="row">
														<div className="col">
															Current Number of Dancers
														</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<input
																	type="text"
																	className="form-control"
																	value={this.state.numbers}
																	aria-label="current num"
																	disabled
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col">
													<div className="row">
														<div className="col">
															Maximum Number of Dancers
														</div>
													</div>
													<div className="row">
														<div className="col">
															<div className="input-group mb-3">
																<input
																	type="text"
																	className="form-control"
																	value={this.state.values.max_num}
																	aria-label="max num"
																	id="max_num"
																	onChange={this.handleInputChange}
																	disabled={this.state.restrictEdit}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="text-right">
											{this.state.msg}{" "}
											{this.state.restrictEdit ? (
												""
											) : (
												<button
													className="btn bg-purple margin"
													onClick={this.submitInformation}
													disabled={this.state.loading}
												>
													{this.state.loading ? (
														<div className="d-flex justify-content-center">
															<div
																className="spinner-border"
																role="status"
															>
																<span className="sr-only"></span>
															</div>
														</div>
													) : (
														<strong>Save</strong>
													)}
												</button>
											)}
										</div>
									</div>
									<div
										className="tab-pane fade rounded-bottom"
										id="dancers"
										role="tabpanel"
										aria-labelledby="dancers-tab"
									>
										<div
											className={
												window.innerWidth < 770
													? "container padding-left-0 padding-right-0 rounded-bottom"
													: "container rounded-bottom"
											}
										>
											<div className="row">
												<div
													className={
														window.innerWidth < 770
															? "col padding-right-2 padding-left-2"
															: "col"
													}
												>
													<ul className="list-group list-group-flush rounded-bottom">
														<li
															className={
																window.innerWidth < 770
																	? "bg-light-purple list-group-item padding-0"
																	: "bg-light-purple list-group-item"
															}
														>
															<div className="container">
																<div className="row d-sm-flex">
																	<div
																		className={
																			window.innerWidth < 770
																				? "col padding-right-2 padding-left-2 text-center"
																				: "col"
																		}
																	>
																		<strong>First Name</strong>
																	</div>
																	<div
																		className={
																			window.innerWidth < 770
																				? "col padding-right-2 padding-left-2 text-center"
																				: "col"
																		}
																	>
																		<strong>Last Name</strong>
																	</div>
																	<div
																		className={
																			window.innerWidth < 770
																				? "col padding-right-2 padding-left-2 text-center"
																				: "col"
																		}
																	>
																		<strong>Date Of Birth</strong>
																	</div>
																	<div
																		className={
																			window.innerWidth < 770
																				? "col padding-right-2 padding-left-2 text-center"
																				: "col"
																		}
																	>
																		<strong>School Year</strong>
																	</div>
																	<div
																		className={
																			window.innerWidth < 770
																				? "col padding-right-2 padding-left-2 text-center"
																				: "col"
																		}
																	>
																		<strong> </strong>
																	</div>
																</div>
															</div>
														</li>
														{this.state.loading ? (
															<div className="d-flex justify-content-center">
																<div
																	className="spinner-border"
																	role="status"
																>
																	<span className="sr-only"></span>
																</div>
															</div>
														) : (
															""
														)}
														{this.state.dancers.length <= 0 ? (
															<li className="bg-light-purple list-group-item">
																<div className="col rounded">
																	<div className="container margin-top-bottom">
																		<div className="row align-items-center">
																			<div className="col">
																				No pupils in this
																				class
																			</div>
																		</div>
																	</div>
																</div>
															</li>
														) : (
															this.state.dancers.map((dancer, index) => {
																return (
																	<li
																		className={
																			window.innerWidth < 770
																				? "bg-light-purple list-group-item padding-0"
																				: "bg-light-purple list-group-item"
																		}
																		key={index}
																	>
																		<div className="col rounded">
																			<div
																				className={
																					window.innerWidth <
																					770
																						? "container margin-top-bottom"
																						: "container margin-top-bottom"
																				}
																			>
																				<div className="row align-items-center">
																					<div
																						className={
																							window.innerWidth <
																							770
																								? "col padding-right-2 padding-left-2 text-center"
																								: "col"
																						}
																					>
																						{
																							dancer.first_name
																						}
																					</div>
																					<div
																						className={
																							window.innerWidth <
																							770
																								? "col padding-right-2 padding-left-2 text-center"
																								: "col"
																						}
																					>
																						{
																							dancer.last_name
																						}
																					</div>
																					<div
																						className={
																							window.innerWidth <
																							770
																								? "col padding-right-2 padding-left-2 text-center"
																								: "col"
																						}
																					>
																						{this.getDoB(
																							dancer.dob
																						)}
																					</div>
																					<div
																						className={
																							window.innerWidth <
																							770
																								? "col padding-right-2 padding-left-2 text-center"
																								: "col"
																						}
																					>
																						{
																							dancer.school_year
																						}
																					</div>
																					<div
																						className={
																							window.innerWidth <
																							770
																								? "col padding-right-2 padding-left-2 text-right"
																								: "col text-right"
																						}
																					>
																						<Link
																							type="button"
																							className="btn btn-lg"
																							to={
																								"/dancer/profile/" +
																								dancer.pupil_id
																							}
																						>
																							<i className="bi bi-search"></i>
																						</Link>
																						{this
																							.state
																							.showTransfer ? (
																							<>
																								<button
																									type="button"
																									className="btn btn-lg"
																									data-bs-toggle="modal"
																									data-bs-target="#transferModal"
																									onClick={() => {
																										this.setState(
																											{
																												transfer:
																													{
																														first_name:
																															dancer.first_name,
																														pupil_id:
																															dancer.pupil_id,
																													},
																											}
																										);
																									}}
																								>
																									<i className="bi bi-arrow-left-right"></i>
																								</button>
																							</>
																						) : (
																							""
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</li>
																);
															})
														)}
													</ul>
												</div>
												{CryptoJS.AES.decrypt(
													Cookies.get("role"),
													process.env.REACT_APP_SECRET_KEY
												)
													.toString(CryptoJS.enc.Utf8)
													.includes("Admin") ? (
													<div
														className="modal fade"
														id="transferModal"
														data-bs-backdrop="static"
														data-bs-keyboard="false"
														tabIndex="-1"
														aria-labelledby="transferModalLabel"
														aria-hidden="true"
													>
														<div className="modal-dialog">
															<div className="modal-content bg-purple rounded">
																<div className="modal-header">
																	<h1
																		className="modal-title fs-5"
																		id="transferModalLabel"
																	>
																		Transfer{" "}
																		{
																			this.state.transfer
																				.first_name
																		}
																	</h1>
																	<button
																		type="button"
																		className="btn-close"
																		data-bs-dismiss="modal"
																		aria-label="Close"
																		onClick={() =>
																			this.resetData()
																		}
																	></button>
																</div>
																<div className="modal-body bg-light-purple rounded padding text-center margin-left-right">
																	<div className="container">
																		<div className="row">
																			<div className="col">
																				<strong>
																					Current Class:
																				</strong>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				{
																					this.state
																						.values
																						.name
																				}
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<strong>
																					New Class
																				</strong>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="input-group mb-3">
																					<select
																						className="form-select"
																						aria-label="Default select example"
																						id="new_class"
																						onChange={
																							this
																								.handleInputChange
																						}
																					>
																						<option value="">
																							Select
																							class
																						</option>
																						{this.state.classes.map(
																							(
																								classObj,
																								index
																							) => {
																								return (
																									<option
																										value={
																											classObj.class_id
																										}
																										key={
																											index
																										}
																									>
																										{
																											classObj.class_name
																										}
																									</option>
																								);
																							}
																						)}
																					</select>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				{this.state.msg}
																			</div>
																		</div>
																	</div>
																</div>
																<div className="modal-footer">
																	<button
																		type="button"
																		className="btn bg-light-purple"
																		onClick={() =>
																			this.handleTransferClick(
																				this.state.transfer
																					.pupil_id
																			)
																		}
																		disabled={
																			this.state.msg !== "" ||
																			this.state.values
																				.new_class === ""
																		}
																	>
																		Save
																	</button>
																</div>
															</div>
														</div>
													</div>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ClassInfo;
