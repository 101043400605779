import React, { Component } from "react";

export class ChildMedicalInfo extends Component {
	state = {
		values: {
			medical_information: this.props.onboarding_state.child.medical_information,
			allergies: this.props.onboarding_state.child.allergies,
		},
		errorMsg: "",
	};

	decreaseStage() {
		this.props.decreaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();
		this.props.increaseStage(4, this.state.values);
	};

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="row justify-content-center align-items-center">
				<div className="col col-md-8 rounded bg-purple margin-left-right">
					<div className="container">
						<div className="row">
							<div className="col padding">
								<p className={window.innerWidth < 770 ? "text-center h4" : "text-right h3"}>
									Child Medical Information
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col rounded padding margin-bottom bg-light-purple">
								<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												This section of the onboarding form requires your child's
												information. Please speak to a member of the Management Team
												if further information or guidance is required.
												<br />
												If there is no medical information, please leave this box
												empty.
											</div>
										</div>
										<div className="row">
											<div className="col margin-top-bottom">
												<div className="input-group has-validation">
													<div className="form-floating">
														<textarea
															className="form-control"
															id="medical_information"
															placeholder="medical_information"
															onChange={this.handleInputChange}
															value={this.state.values.medical_information}
															rows="4"
														/>
														<label
															htmlFor="medical_information"
															className="login-text-override"
														>
															Medical Information
														</label>
													</div>
													<div className="invalid-feedback">
														Please enter your child's medical information.
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col margin-top-bottom">
												<button
													className="btn bg-purple"
													onClick={() => this.decreaseStage()}
												>
													Back
												</button>
											</div>
											<div className="col margin-top-bottom text-right">
												<button className="btn bg-purple" type="submit">
													Next
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ChildMedicalInfo;
