import React, { Component } from "react";
import LandingPage from "./LandingPage.jsx";
import ParentAccountCreation from "./ParentAccountCreation.jsx";
import ParentContactInfo from "./ParentContactInfo.jsx";
import ChildBasicInfo from "./ChildBasicInfo.jsx";
import ChildMedicalInfo from "./ChildMedicalInfo.jsx";
import SecondaryNextOfKin from "./SecondaryNextOfKin.jsx";
import DancerRules from "./DancerRules.jsx";
import ChildMiscInfo from "./ChildMiscInfo.jsx";
import Review from "./Review.jsx";
import axios from "axios";
import CryptoJS, { AES } from "crypto-js";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

export class Onboarding extends Component {
	state = {
		id: this.props.id,
		code: this.props.code,
		current_stage: 0,
		max_stages: 8,
		parent: {
			first_name: "",
			last_name: "",
			email: "",
			password: "",
			phone_number: "",
			address_line1: "",
			address_line2: "",
			town: "",
			county: "",
			postcode: "",
			alreadyRegistered: false,
		},
		child: {
			first_name: "",
			last_name: "",
			dob: "",
			medical_information: "",
			allergies: "",
			dancer_rules_accepted: "n",
			school_year: "",
			shirt_size: "",
			nok_2_name: "",
			nok_2_phone: "",
			nok_2_relation: "",
			primary_nok_relation: "",
			additional_info: "",
			photo_consent: "n",
			user_id: 0,
		},
		errorMsg: "",
		loading: false,
		successfulLogin: false,
	};

	async componentDidMount() {
		this.getTempPupilData();
	}

	async getTempPupilData() {
		var res;
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/temp-pupils/" + this.state.id, {
				params: {
					code: this.state.code,
				},
			})
			.then((response) => {
				if (response.status === 200) {
					res = response.data;
					var temparent = this.state.parent;
					var tempchild = this.state.child;
					temparent.first_name = res.parent_first_name;
					temparent.last_name = res.parent_last_name;
					temparent.email = res.parent_email;
					tempchild.first_name = res.first_name;
					tempchild.last_name = res.last_name;
					tempchild.dob = res.dob;
					tempchild.school_year = res.school_year;
					this.setState({ child: tempchild, parent: temparent });
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else {
					this.setState({ current_stage: 9 });
				}
			});
	}

	resetData() {
		this.setState({
			child: {
				first_name: "",
				last_name: "",
				dob: "",
				medical_conditions: "",
				dancer_rules_accepted: "n",
				school_year: "",
				shirt_size: "",
				nok_2_name: "",
				nok_2_phone: "",
				nok_2_relation: "",
				primary_nok_relation: "",
				additional_info: "",
				photo_consent: "n",
			},
			parent: {
				first_name: "",
				last_name: "",
				email: "",
				password: "",
				phone_number: "",
				address_line1: "",
				address_line2: "",
				town: "",
				county: "",
				postcode: "",
			},
		});
		this.getTempPupilData();
	}

	increaseStage = (stage, object) => {
		var tempParent = this.state.parent;
		var tempChild = this.state.child;
		switch (stage) {
			case 1:
				if (tempParent.password !== object.password) {
					tempParent.password = object.password;
				}
				break;
			case 2:
				if (tempParent.phone_number !== object.phone_number) {
					tempParent.phone_number = object.phone_number;
				}
				if (tempParent.address_line1 !== object.address_line1) {
					tempParent.address_line1 = object.address_line1;
				}
				if (tempParent.address_line2 !== object.address_line2) {
					tempParent.address_line2 = object.address_line2;
				}
				if (tempParent.town !== object.town) {
					tempParent.town = object.town;
				}
				if (tempParent.county !== object.county) {
					tempParent.county = object.county;
				}
				if (tempParent.postcode !== object.postcode) {
					tempParent.postcode = object.postcode;
				}
				break;
			case 3:
				if (tempChild.primary_nok_relation !== object.primary_nok_relation) {
					tempChild.primary_nok_relation = object.primary_nok_relation;
				}
				break;
			case 4:
				if (tempChild.medical_information !== object.medical_information) {
					tempChild.medical_information = object.medical_information;
				}
				if (tempChild.allergies !== object.allergies) {
					tempChild.allergies = object.allergies;
				}
				break;
			case 5:
				if (tempChild.nok_2_name !== object.nok_2_name) {
					tempChild.nok_2_name = object.nok_2_name;
				}
				if (tempChild.nok_2_phone !== object.nok_2_phone) {
					tempChild.nok_2_phone = object.nok_2_phone;
				}
				if (tempChild.nok_2_relation !== object.nok_2_relation) {
					tempChild.nok_2_relation = object.nok_2_relation;
				}
				break;
			case 6:
				if (tempChild.shirt_size !== object.shirt_size) {
					tempChild.shirt_size = object.shirt_size;
				}
				if (tempChild.additional_info !== object.additional_info) {
					tempChild.additional_info = object.additional_info;
				}
				if (tempChild.photo_consent !== object.photo_consent) {
					tempChild.photo_consent = object.photo_consent;
				}
				break;
			case 7:
				if (tempChild.dancer_rules_accepted !== object.dancer_rules_accepted) {
					tempChild.dancer_rules_accepted = object.dancer_rules_accepted;
				}
				break;
			case 8:
				// Send to API
				break;
			default:
				break;
		}
		this.setState({ current_stage: this.state.current_stage + 1, parent: tempParent, child: tempChild });
	};

	sendInformationToAPI = async () => {
		this.setState({ loading: true });
		var res = "Error Occured";
		var body = this.state.child;
		body.temp_pupil_token = this.state.code;
		body.temp_pupil_id = Number(this.state.id);
		body.user_id = Number(
			jwtDecode(
				CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
					CryptoJS.enc.Utf8
				)
			).sub
		);
		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/pupils", body, {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					window.location.replace("/success");
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					res = error.response.data;
				}
			});

		this.setState({ errorMsg: res, loading: false });
	};

	loginAndSubmitCookie = (stage, values, response) => {
		var encryptedToken = AES.encrypt(response.jwt, process.env.REACT_APP_SECRET_KEY).toString();
		var encryptedRole = AES.encrypt("Parent", process.env.REACT_APP_SECRET_KEY).toString();
		Cookies.set("token", encryptedToken, { expires: 0.5 });
		Cookies.set("role", encryptedRole, { expires: 0.5 });
		var tempchild = this.state.child;
		tempchild.user_id = response.id;
		this.setState({ child: tempchild });
		this.increaseStage(stage, values);
	};

	decreasedStage = () => {
		if (this.state.current_stage > 0) {
			if (this.state.current_stage === 1) {
				this.resetData();
			}
			this.setState({ current_stage: this.state.current_stage - 1 });
		} else {
			console.error("Error at decreaseStage");
		}
	};

	goToPage = (id) => {
		if (id > 0 && id <= this.state.max_stages) {
			this.setState({ current_stage: id });
		} else {
			console.error("Error at goToPage");
		}
	};

	getPagesArray() {
		var array = [];
		for (let i = 1; i < this.state.max_stages + 1; i++) {
			array.push(i);
		}
		return array;
	}

	getCSSClassName(id) {
		var classname = "rounded";
		var stage;
		if (this.state.parent.alreadyRegistered) {
			stage = this.state.current_stage - 2;
		} else {
			stage = this.state.current_stage;
		}
		if (id === stage) {
			return classname + " bg-purple";
		}
		return classname;
	}

	LoggedInUser = (response) => {
		var temp_parent = this.state.parent;
		temp_parent.alreadyRegistered = !temp_parent.alreadyRegistered;
		this.props.setToken(response.data);
		this.props.setRole(response.role);
		this.setState({ parent: temp_parent, max_stages: 6, current_stage: 3 });
	};

	render() {
		switch (this.state.current_stage) {
			case 0:
				return (
					<LandingPage
						increaseStage={this.increaseStage}
						decreaseStage={this.decreasedStage}
						loggedIn={this.LoggedInUser}
					/>
				);
			case 1:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<ParentAccountCreation
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
						/>
					</div>
				);
			case 2:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<ParentContactInfo
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
							loginAndSubmitCookie={this.loginAndSubmitCookie}
						/>
					</div>
				);
			case 3:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<ChildBasicInfo
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
							noBackButton={this.state.parent.alreadyRegistered}
						/>
					</div>
				);
			case 4:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<ChildMedicalInfo
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
						/>
					</div>
				);
			case 5:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<SecondaryNextOfKin
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
						/>
					</div>
				);
			case 6:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<ChildMiscInfo
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
						/>
					</div>
				);
			case 7:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<DancerRules
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
						/>
					</div>
				);
			case 8:
				return (
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col col-md-8 margin padding text-center">
								<div className="row d-flex justify-content-center">
									{this.getPagesArray().map((page, index) => (
										<div className="col col-md-1" key={index}>
											<div className={this.getCSSClassName(page)}>{page}</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<Review
							onboarding_state={this.state}
							increaseStage={this.increaseStage}
							decreaseStage={this.decreasedStage}
							goToPage={this.goToPage}
							submit={this.sendInformationToAPI}
						/>
					</div>
				);
			case 9:
				return (
					<div className="text-center">
						<h2>Invalid or Expired Link</h2>
						<br />
						<h5>
							It seems you have used an invalid or expired link. If you still require to onboard a
							new dancer, please contact the Management team.
							<br />
							Click <Link to="/login">here</Link> to go to the login page.
						</h5>
					</div>
				);
			default:
				window.location.replace("/not-found");
		}
	}
}

export default Onboarding;
