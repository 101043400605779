import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import DancerSearch from "./DancerSearch";
import Classes from "./Classes";
import ToDoList from "./ToDoList";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import ImportantInfo from "./ImportantInfo";

export class Admin extends Component {
	state = {
		name: "",
		role: "",
		dancers: [],
	};

	async componentDidMount() {
		var user = {};
		await axios
			.get(
				process.env.REACT_APP_API_END_POINT +
					"/api/users/" +
					jwtDecode(
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						)
					).sub,
				{
					headers: {
						Authorization:
							"Bearer " +
							CryptoJS.AES.decrypt(
								Cookies.get("token"),
								process.env.REACT_APP_SECRET_KEY
							).toString(CryptoJS.enc.Utf8),
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					user = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({
			name: user.first_name,
			role: CryptoJS.AES.decrypt(Cookies.get("role"), process.env.REACT_APP_SECRET_KEY).toString(
				CryptoJS.enc.Utf8
			),
		});
	}

	render() {
		return (
			<div>
				<Navbar title="Admin Dashboard" />
				<div className="container rounded padding-md">
					<div className="row">
						<div className="col" id="left">
							<div className="container">
								<div className="row">
									<div className="col">
										<div className="container bg-purple rounded padding margin-bottom">
											<div className="row justify-content-end">
												<div className="col">
													<h3 className="text-light-purple text-right">
														Welcome {this.state.name}!
													</h3>
												</div>
											</div>
											<div className="row">
												<div className="col bg-light-purple rounded margin-left margin-right">
													<p className="margin-top">
														Welcome to the Phoenix Amateur Dance Group
														Administration Site Admin Dashboard. From here you
														can complete a variety of actions such as amend
														dancer profiles, complete to-do tasks and see
														current classes.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<DancerSearch />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Classes />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<ImportantInfo />
									</div>
								</div>
							</div>
						</div>
						<div className="col" id="right">
							<div className="container">
								<div className="row">
									<div className="col rounded">
										<ToDoList />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Admin;
