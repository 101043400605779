import React, { Component } from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import axios from "axios";

export class Classes extends Component {
	state = {
		classes: [],
		loading: false,
		error_msg: "",
	};

	async componentDidMount() {
		var classes;
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/classes", {
				headers: {
					Authorization:
						"Bearer " +
						CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(
							CryptoJS.enc.Utf8
						),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					classes = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});
		this.setState({ classes: classes, loading: false });
	}

	render() {
		return (
			<div className="container bg-purple rounded padding margin-bottom">
				<div className="row justify-content-end">
					<div className="col">
						<Link
							to="/classes"
							className="link-offset-2 link-underline link-underline-opacity-0 text-white"
						>
							<h3 className="text-light-purple text-right">Classes</h3>
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col bg-light-purple rounded margin-left margin-right">
						<div className="container">
							<div className="container overflow-auto height-200 text-center">
								<ul className="list-group list-group-flush">
									<li className="bg-light-purple list-group-item">
										<div className="container">
											<div className="row">
												<div className="col">
													<b>Name</b>
												</div>
												<div className="col">
													<b>Teacher</b>
												</div>
											</div>
										</div>
									</li>
									{this.state.loading ? (
										<li className="bg-light-purple list-group-item">
											<div className="spinner-border" role="status">
												<span className="sr-only"></span>
											</div>
										</li>
									) : (
										this.state.classes.map((classes, index) => (
											<li key={index} className="bg-light-purple list-group-item">
												<Link
													to={"/class/info/" + classes.class_id}
													className="link-underline link-underline-opacity-0 text-purple"
												>
													<div className="container">
														<div className="row">
															<div className="col">
																{classes.class_name}
															</div>
															<div className="col">
																{classes.first_name}
															</div>
														</div>
													</div>
												</Link>
											</li>
										))
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Classes;
