import axios from "axios";
import { AES } from "crypto-js";
import Cookies from "js-cookie";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export class LandingPage extends Component {
	state = {
		values: {
			email: "",
			password: "",
			recovery_email: "",
			role: "Parent",
		},
		errorMsg: "",
		msg: "",
		loading: false,
	};

	increaseStage() {
		this.props.increaseStage();
	}

	submitValue = (event) => {
		event.preventDefault();
		if (this.state.values.email === "" || this.state.values.email === " ") {
			this.setState({ errorMsg: "Email cannot be empty" });
			return;
		}
		if (this.state.values.password === "" || this.state.values.password === " ") {
			this.setState({ errorMsg: "Password cannot be empty" });
			return;
		}
		this.loginToAPI();
	};

	submitPasswordRecovery = (event) => {
		event.preventDefault();
		if (this.state.values.recovery_email === "" || this.state.values.recovery_email === " ") {
			this.setState({ msg: "Recovery email cannot be empty" });
			return;
		} else {
			this.sendRecoveryEmail();
		}
	};

	async sendRecoveryEmail() {
		var body = { email: this.state.values.recovery_email };
		var msg;

		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/users/request-password-reset", body)
			.then((response) => {
				msg = "Submitted";
			})
			.catch((error) => {
				msg = "Error Occured";
			});
		this.setState({ msg: msg });
	}

	loginToAPI() {
		this.setState({ loading: true });
		axios.post(process.env.REACT_APP_API_END_POINT + "/api/users/login", {
			email: this.state.values.email,
			password: this.state.values.password,
			role: this.state.values.role,
		})
			.then((response) => {
				//console.log(response.data);
				if (response.status === 200) {
					var encryptedToken = AES.encrypt(response.data, process.env.REACT_APP_SECRET_KEY).toString();
					var encryptedRole = AES.encrypt(
						this.state.values.role,
						process.env.REACT_APP_SECRET_KEY
					).toString();
					Cookies.set("token", encryptedToken, { expires: 0.5 });
					Cookies.set("role", encryptedRole, { expires: 0.5 });
					response.role = this.state.values.role;
					this.props.loggedIn(response);
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errorMsg: error.response.data,
						loading: false,
					});
				} else if (error.request) {
					console.error(error.request);

					this.setState({
						errorMsg: error.request,
						loading: false,
					});
				} else {
					console.error("Error", error.message);
				}
			});
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<div className="container justify-content-center align-items-center">
				<div className="row justify-content-center align-items-center">
					<div className="col col-md-8 bg-purple rounded margin d-flex align-items-center">
						<div className="container">
							<div className="row">
								<div className="col margin-top margin-right-sm">
									<p
										className={
											window.innerWidth < 770 ? "text-center h4" : "text-right h3"
										}
									>
										Child Onboarding Landing Page
									</p>
								</div>
							</div>
							<div className="row justify-content-center">
								<div className="col">
									<div className="container text-center">
										<div className="row">
											<div
												className={
													window.innerWidth < 770
														? "col margin-right-sm margin-left-sm margin-top-bottom-lg bg-light-purple rounded"
														: "col margin-right-sm margin-left-sm margin-top-bottom-lg bg-light-purple rounded-left"
												}
											>
												<div className="row">
													<div className="col margin">
														<h4>
															<strong>Already registered?</strong>
														</h4>
													</div>
												</div>
												<div className="row">
													<div className="col margin">
														<p>
															Login here using your account and enter your
															child's information.
														</p>
													</div>
												</div>
												<form
													className="row g-3 needs-validation"
													onSubmit={this.submitValue}
												>
													<div className="col">
														<div className="input-group has-validation">
															<div className="form-floating">
																<input
																	type="text"
																	className="form-control"
																	id="email"
																	placeholder="Email"
																	value={this.state.values.email}
																	onChange={this.handleInputChange}
																/>
																<label
																	htmlFor="email"
																	className="login-text-override"
																>
																	Email
																</label>
															</div>
															<div className="invalid-feedback">
																Please enter your username.
															</div>
														</div>
													</div>

													<div className="col-12">
														<div className="input-group has-validation">
															<div className="form-floating">
																<input
																	type="password"
																	className="form-control"
																	id="password"
																	placeholder="Password"
																	value={this.state.values.password}
																	onChange={this.handleInputChange}
																/>
																<label
																	htmlFor="password"
																	className="login-text-override"
																>
																	Password
																</label>
															</div>
															<div className="invalid-feedback">
																Please enter your password!
															</div>
														</div>
													</div>
													<div className="col-12">{this.getError()}</div>
													<div className="col-12 margin-bottom">
														<button className="btn bg-purple" type="submit">
															{this.state.loading ? (
																<div
																	className="spinner-border"
																	role="status"
																>
																	<span className="sr-only"></span>
																</div>
															) : (
																"Login"
															)}
														</button>
													</div>
													<div className="col-12 text-center">
														<p className="small mb-0">
															<Link
																type="button"
																data-bs-toggle="modal"
																data-bs-target="#addItem"
																className="badge badge-info text-purple"
															>
																Forgotten Password?
															</Link>
														</p>
													</div>
												</form>
											</div>
											<div
												className={
													window.innerWidth < 770
														? "col rounded margin-left-sm margin-right-sm margin-top-bottom-lg bg-light-purple rounded"
														: "col rounded-right margin-left-sm margin-right-sm margin-top-bottom-lg bg-light-purple"
												}
											>
												<div className="row">
													<div className="col margin">
														<h4>
															<strong>First Time?</strong>
														</h4>
													</div>
												</div>
												<div className="row">
													<div className="col margin">
														<p>
															Register for an account and add your child's
															information.
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col margin">
														<button
															type="button"
															className="btn"
															onClick={() => this.increaseStage()}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="100"
																height="100"
																fill="#5a0526"
																className="bi bi-forward-fill"
																viewBox="0 0 16 16"
															>
																<path d="m9.77 12.11 4.012-2.953a.647.647 0 0 0 0-1.114L9.771 5.09a.644.644 0 0 0-.971.557V6.65H2v3.9h6.8v1.003c0 .505.545.808.97.557z" />
															</svg>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="addItem"
					tabIndex="-1"
					aria-labelledby="addItemLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-lg">
						<div className="modal-content bg-purple">
							<div className="modal-header">
								<h5 className="modal-title" id="addItemLabel">
									Password Reset
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body bg-light-purple margin rounded">
								<form
									className="row g-3 needs-validation"
									onSubmit={this.submitPasswordRecovery}
								>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												If you have forgotten your password, please enter your email
												address below. We will send you a password reset link if you
												have an account with us. If you've forgotten your email
												address, please contact the website administrator or Phoenix
												staff.
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="row margin">
												<div className="col">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="text"
																className="form-control"
																id="recovery_email"
																placeholder="recovery_email"
																onChange={this.handleInputChange}
																value={this.state.values.recovery_email}
																required
															/>
															<label
																htmlFor="recovery_email"
																className="login-text-override"
															>
																Email
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="row margin">
												<div className="col">{this.state.msg}</div>
												<div className="col text-right">
													<button
														className="btn bg-purple"
														type="submit"
														disabled={this.state.msg}
													>
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPage;
