import React, { Component } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export class Users extends Component {
	state = {
		users: [],
		filtered: [],
		values: {
			first_name: "",
			last_name: "",
			email: "",
			hashed_password: "",
			contact_number: "",
			first_line: "",
			second_line: "",
			town: "",
			county: "",
			postcode: "",
		},
		search: "",
		errorMsg: "",
		msg: "",
		confirm_delete: false,
		loading: false,
	};

	async componentDidMount() {
		var users = [];
		this.setState({ loading: true });
		await axios
			.get(process.env.REACT_APP_API_END_POINT + "/api/users", {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					users = response.data;
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					window.location.replace("/error");
				}
			});

		this.setState({
			users: users,
			filtered: users,
			loading: false,
			values: {
				first_name: "",
				last_name: "",
				email: "",
				hashed_password: "",
				contact_number: "",
				first_line: "",
				second_line: "",
				town: "",
				county: "",
				postcode: "",
			},
			msg: "",
			confirm_delete: false,
		});
	}

	inputHandler = (event) => {
		var text = event.target.value;
		this.setState({ search: text });
		if (text.length <= 1) {
			this.setState({ filtered: this.state.users });
		} else {
			this.setState({
				filtered: this.state.users.filter((user) => user.first_name.toLowerCase().includes(text) || user.last_name.toLowerCase().includes(text)),
			});
		}
	};

	submitValue = (event) => {
		event.preventDefault();
		this.setState({
			errorMsg: "",
		});
		for (var property in this.state.values) {
			// Must be a better way to validate fields to stop users from just putting in spaces
			if (property === "second_line" || property === "county") {
				continue;
			}
			if (this.state.values[property] === "" || this.state.values[property] === " ") {
				this.setState({
					errorMsg: property.replace("_", " ") + " field has not been filled in correctly",
				});
				return;
			}
		}
		this.sendToBackend();
	};

	async sendToBackend() {
		this.setState({ loading: true });
		var msg;
		var body = this.state.values;
		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/users/create", body, {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 200) {
					msg = "User Created";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	deleteUser = async (id) => {
		this.setState({ loading: true });
		var msg;
		await axios
			.delete(process.env.REACT_APP_API_END_POINT + "/api/users/" + id + "/delete", {
				headers: {
					Authorization: "Bearer " + CryptoJS.AES.decrypt(Cookies.get("token"), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8),
				},
			})
			.then((response) => {
				if (response.status === 204) {
					msg = "User Deleted";
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					msg = error.response.data;
				}
			});
		this.setState({ msg: msg, loading: false });
	};

	render() {
		return (
			<div>
				<Navbar title="Users" />
				<div className="container padding-md justify-content-center align-items-center text-wrap">
					<div className="row">
						<div className="col">
							<div className="container bg-purple rounded padding margin-bottom">
								<div className="row">
									<div className={window.innerWidth < 770 ? "col margin-bottom margin-left-sm margin-right-sm padding-0" : "col margin-bottom margin-left margin-right"}>
										<div>
											<input
												className="form-control form-control-lg bg-light-purple"
												type="text"
												placeholder="Search for user"
												onChange={this.inputHandler}
												value={this.state.search}
											></input>
										</div>
									</div>
									<div
										className={
											window.innerWidth < 770
												? "col-2 col-md-6 margin-bottom margin-left-sm margin-right-sm padding-0 d-flex align-items-center justify-content-center"
												: "col-2 col-md-6 margin-bottom margin-left margin-right d-flex align-items-center justify-content-end"
										}
									>
										<h3 className="text-right">
											<i type="button" data-bs-toggle="modal" data-bs-target="#addItem" className="bi bi-plus-square bg-purple"></i>
										</h3>
										<div className="modal fade" id="addItem" tabIndex="-1" aria-labelledby="addItemLabel" aria-hidden="true">
											<div className="modal-dialog modal-lg">
												<div className="modal-content bg-purple">
													<div className="modal-header">
														<h5 className="modal-title" id="addItemLabel">
															Add New User
														</h5>
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
															onClick={() => this.componentDidMount()}
														></button>
													</div>
													<div className="modal-body bg-light-purple margin rounded">
														<form className="row g-3 needs-validation" onSubmit={this.submitValue}>
															<div className="container">
																<div className="row">
																	<div className="col padding text-center">
																		Here you can add a new user to the system. This user will be created with no roles, so ensure that you assign
																		their role once the user is made.
																	</div>
																</div>
																<div className="row justify-content-center">
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="first_name"
																						placeholder="Parent First Name"
																						onChange={this.handleInputChange}
																						value={this.state.values.first_name}
																						required
																					/>
																					<label htmlFor="first_name" className="login-text-override">
																						First Name
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="last_name"
																						placeholder="Parent Last Name"
																						onChange={this.handleInputChange}
																						value={this.state.values.last_name}
																						required
																					/>
																					<label htmlFor="last_name" className="login-text-override">
																						Last Name
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-12 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="email"
																						className="form-control"
																						id="email"
																						placeholder="Email"
																						onChange={this.handleInputChange}
																						value={this.state.values.email}
																						required
																					/>
																					<label htmlFor="email" className="login-text-override">
																						Email
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="hashed_password"
																						placeholder="hashed_password"
																						onChange={this.handleInputChange}
																						value={this.state.values.hashed_password}
																						required
																					/>
																					<label htmlFor="hashed_password" className="login-text-override">
																						Password
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="contact_number"
																						placeholder="contact_number"
																						onChange={this.handleInputChange}
																						value={this.state.values.contact_number}
																						required
																					/>
																					<label htmlFor="contact_number" className="login-text-override">
																						Contact Number
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-12 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="first_line"
																						placeholder="first_line"
																						onChange={this.handleInputChange}
																						value={this.state.values.first_line}
																						required
																					/>
																					<label htmlFor="first_line" className="login-text-override">
																						First Line Address
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-12 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="second_line"
																						placeholder="second_line"
																						onChange={this.handleInputChange}
																						value={this.state.values.second_line}
																					/>
																					<label htmlFor="second_line" className="login-text-override">
																						Second Line Address
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-12 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="town"
																						placeholder="town"
																						onChange={this.handleInputChange}
																						value={this.state.values.town}
																						required
																					/>
																					<label htmlFor="town" className="login-text-override">
																						Town
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="county"
																						placeholder="county"
																						onChange={this.handleInputChange}
																						value={this.state.values.county}
																					/>
																					<label htmlFor="county" className="login-text-override">
																						County
																					</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6 margin-top-bottom">
																			<div className="input-group has-validation">
																				<div className="form-floating">
																					<input
																						type="text"
																						className="form-control"
																						id="postcode"
																						placeholder="postcode"
																						onChange={this.handleInputChange}
																						value={this.state.values.postcode}
																						required
																					/>
																					<label htmlFor="postcode" className="login-text-override">
																						Postcode
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col">{this.getError()}</div>
																	</div>
																	<div className="row">
																		<div className="col text-right">
																			{this.state.msg}{" "}
																			<button
																				className="btn bg-purple"
																				type="submit"
																				disabled={this.state.loading || this.state.msg === "User Created"}
																			>
																				Submit
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className={window.innerWidth < 770 ? "col bg-purple" : "col bg-purple margin"}>
										<div className="container">
											<div className="row">
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Name</p>
												</div>
												<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
													<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>Role</p>
												</div>
												{window.innerWidth < 770 ? (
													<></>
												) : (
													<div className={window.innerWidth < 770 ? "col padding-sm" : "col"}>
														<p className={window.innerWidth < 770 ? "h5 fw-bold" : "h4 fw-bold"}>
															<strong></strong>
														</p>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								{this.state.loading ? (
									<div className="d-flex justify-content-center">
										<div className="spinner-border" role="status">
											<span className="sr-only"></span>
										</div>
									</div>
								) : (
									""
								)}
								{this.state.filtered.map((user, index) => {
									return (
										<div className="row" key={index}>
											<div
												className={
													window.innerWidth < 770
														? "col bg-light-purple rounded margin-bottom margin-left-sm margin-right-sm"
														: "col bg-light-purple rounded margin-bottom margin-left margin-right"
												}
												onClick={() => {
													if (window.innerWidth < 770) {
														window.location.href = "/user/profile/" + user.user_id;
													}
												}}
											>
												<div className="container margin-top-bottom">
													<div className="row align-items-center">
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>
																{user.first_name} {user.last_name}
															</strong>
														</div>
														<div className={window.innerWidth < 770 ? "col text-break text-wrap padding-right-2 padding-left-2" : "col"}>
															<strong>{user.roles.join(", ")}</strong>
														</div>
														{window.innerWidth < 770 ? (
															<></>
														) : (
															<div
																className={
																	window.innerWidth < 770 ? "col text-right text-break text-wrap padding-right-2 padding-left-2" : "col text-right"
																}
															>
																<button type="button" className="btn btn-lg" data-bs-toggle="modal" data-bs-target={"#deleteModal" + index}>
																	<i className="bi bi-trash-fill"></i>
																</button>
																<div
																	className="modal fade"
																	id={"deleteModal" + index}
																	tabindex="-1"
																	aria-labelledby="deleteModalLabel"
																	aria-hidden="true"
																>
																	<div className="modal-dialog">
																		<div className="modal-content bg-purple">
																			<div className="modal-header">
																				<h1 className="modal-title fs-5" id="deleteModalLabel">
																					Deleting User {user.first_name} {user.last_name}
																				</h1>
																				<button
																					type="button"
																					className="btn-close"
																					data-bs-dismiss="modal"
																					aria-label="Close"
																					onClick={() => this.componentDidMount()}
																				></button>
																			</div>
																			<div className="modal-body text-start">
																				<div className="rounded padding bg-light-purple ">
																					You are about to delete {user.first_name} from the system. They will no longer be able to
																					access the site. If they are a parent, all their dancers will also be deleted. This action is
																					irreversible!
																					<br />
																					<input
																						className="form-check-input"
																						type="checkbox"
																						id="confirm_delete"
																						onChange={() => this.setState({ confirm_delete: !this.state.confirm_delete })}
																						checked={this.state.confirm_delete}
																						required
																					/>{" "}
																					<strong>Tick this box to confirm deletion</strong>
																				</div>
																			</div>
																			<div className="modal-footer">
																				{this.state.msg}
																				<button
																					type="button"
																					className="btn btn-danger"
																					onClick={() => this.deleteUser(user.user_id)}
																					disabled={this.state.msg || !this.state.confirm_delete}
																				>
																					Permanently Delete
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
																<Link type="button" className="btn btn-lg" to={"/user/profile/" + user.user_id}>
																	<i className="bi bi-pencil-fill"></i>
																</Link>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Users;
