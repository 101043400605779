import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export class PasswordRecovery extends Component {
	state = {
		token: this.props.token,
		id: this.props.id,
		values: { password: "", confirm_password: "" },
		errorMsg: "",
		loading: false,
		msg: "",
		loginButton: false,
	};

	submitValue = (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		if (this.state.values.password === "" || this.state.values.password === " ") {
			this.setState({ errorMsg: "Password cannot be empty", loading: false });
			return;
		}
		if (this.state.values.confirm_password === "" || this.state.values.confirm_password === " ") {
			this.setState({ errorMsg: "Confirm Password cannot be empty", loading: false });
			return;
		}
		if (this.state.values.password !== this.state.values.confirm_password) {
			this.setState({ errorMsg: "Passwords much match", loading: false });
			return;
		}
		if (this.state.values.password.length < 8) {
			this.setState({ errorMsg: "Password must be at least 8 characters long", loading: false });
			return;
		}
		this.resetPassword();
	};

	resetPassword() {
		axios.post(process.env.REACT_APP_API_END_POINT + "/api/users/reset-password", {
			token: this.state.token,
			password: this.state.values.password,
			user_id: Number(this.state.id),
		})
			.then((response) => {
				if (response.status === 200) {
					this.setState({ msg: "Successful Reset", loading: false, loginButton: true });
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					window.location.replace("/forbidden");
				} else if (error.response.status === 404) {
					window.location.replace("/not-found");
				} else {
					this.setState({ errorMsg: error.message });
				}
			});
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<>
				<div className="container">
					<section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
									{this.getError()}
									<div className="card mb-3 login-color">
										<div className="card-body">
											<div className="pt-4 pb-2">
												<h5 className="card-title text-center pb-0 fs-4">
													Password Recovery
												</h5>
												<p className="text-center small">
													Enter your new password. Passwords must be 8 characters
													long.
												</p>
											</div>

											<form
												className="row g-3 needs-validation"
												onSubmit={this.submitValue}
											>
												<div className="col-12">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="password"
																className="form-control"
																id="password"
																placeholder=""
																value={this.state.values.password}
																onChange={this.handleInputChange}
															/>
															<label
																htmlFor="password"
																className="login-text-override"
															>
																Password
															</label>
														</div>
													</div>
												</div>
												<div className="col-12">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="password"
																className="form-control"
																id="confirm_password"
																placeholder=""
																value={
																	this.state.values.confirm_password
																}
																onChange={this.handleInputChange}
															/>
															<label
																htmlFor="confirm_password"
																className="login-text-override"
															>
																Confirm Password
															</label>
														</div>
													</div>
												</div>
												<div className="col-12 text-center">
													{this.state.msg} <br />
													{this.state.loginButton ? (
														<Link
															className="btn btn-light w-50"
															type="button"
															to="/login"
														>
															Go To Login
														</Link>
													) : (
														<button
															className="btn btn-light w-50"
															type="submit"
														>
															Submit
														</button>
													)}
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</>
		);
	}
}

export default PasswordRecovery;
