import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { AES } from "crypto-js";
import { Link } from "react-router-dom";

export class Login extends Component {
	state = {
		values: {
			email: "",
			password: "",
			role: "Parent",
			recovery_email: "",
		},
		errorMsg: "",
		msg: "",
		loading: false,
	};

	submitValue = (event) => {
		event.preventDefault();
		if (this.state.values.email === "" || this.state.values.email === " ") {
			this.setState({ errorMsg: "Email cannot be empty" });
			return;
		}
		if (this.state.values.password === "" || this.state.values.password === " ") {
			this.setState({ errorMsg: "Password cannot be empty" });
			return;
		}
		if (this.state.values.role === "" || this.state.values.role === " ") {
			this.setState({ errorMsg: "Role cannot be empty" });
			return;
		}
		this.loginToAPI();
	};

	submitPasswordRecovery = (event) => {
		event.preventDefault();
		if (this.state.values.recovery_email === "" || this.state.values.recovery_email === " ") {
			this.setState({ msg: "Recovery email cannot be empty" });
			return;
		} else {
			this.sendRecoveryEmail();
		}
	};

	async sendRecoveryEmail() {
		var body = { email: this.state.values.recovery_email };
		var msg;

		await axios
			.post(process.env.REACT_APP_API_END_POINT + "/api/users/request-password-reset", body)
			.then((response) => {
				msg =
					"Requested Submitted. If you have an account with us, you will receive an email to reset your password.";
			})
			.catch((error) => {
				msg = "Error Occured";
			});
		this.setState({ msg: msg });
	}

	loginToAPI() {
		this.setState({ loading: true });
		axios.post(process.env.REACT_APP_API_END_POINT + "/api/users/login", {
			email: this.state.values.email,
			password: this.state.values.password,
			role: this.state.values.role,
		})
			.then((response) => {
				if (response.status === 200) {
					var encryptedToken = AES.encrypt(response.data, process.env.REACT_APP_SECRET_KEY).toString();
					var encryptedRole = AES.encrypt(
						this.state.values.role,
						process.env.REACT_APP_SECRET_KEY
					).toString();
					Cookies.set("token", encryptedToken, { expires: 0.5 });
					Cookies.set("role", encryptedRole, { expires: 0.5 });

					this.props.setToken(response.data);
					this.props.setRole(this.state.values.role);
				} else if (response.status === 403) {
					window.location.replace("/forbidden");
				} else {
					window.location.replace("/error");
				}
			})
			.catch((error) => {
				this.setState({
					errorMsg: error.response.data ? error.response.data : "Error Occured",
					loading: false,
				});
			});
		this.setState({ loading: false });
	}

	handleInputChange = (event) => {
		const name = event.target.id;
		const value = event.target.value;
		this.setState({ values: { ...this.state.values, [name]: value } });
	};

	getError() {
		if (this.state.errorMsg !== "") {
			return (
				<div className="alert alert-danger" role="alert">
					{this.state.errorMsg}
				</div>
			);
		}
	}

	render() {
		return (
			<>
				<div className="container">
					<section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
									{this.getError()}
									<div className="card mb-3 login-color">
										<div className="card-body">
											<div className="pt-4 pb-2">
												<h5 className="card-title text-center pb-0 fs-4">
													Login to Your Account
												</h5>
												<p className="text-center small">
													Enter your email & password to login
												</p>
											</div>

											<form
												className="row g-3 needs-validation"
												onSubmit={this.submitValue}
											>
												<div className="col-12">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="email"
																className="form-control"
																id="email"
																placeholder=""
																value={this.state.values.email}
																onChange={this.handleInputChange}
															/>
															<label
																htmlFor="email"
																className="login-text-override"
															>
																Email
															</label>
														</div>
													</div>
												</div>

												<div className="col-12">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="password"
																className="form-control"
																id="password"
																placeholder=""
																value={this.state.values.password}
																onChange={this.handleInputChange}
															/>
															<label
																htmlFor="password"
																className="login-text-override"
															>
																Password
															</label>
														</div>
													</div>
												</div>

												<div className="col-12">
													<div className="input-group has-validation">
														<div className="form-floating">
															<select
																className="form-select align-middle"
																aria-label="Role"
																id="role"
																onChange={this.handleInputChange}
																value={this.state.values.role}
															>
																<option value="Parent">Parent</option>
																<option value="Teacher">Teacher</option>
																<option value="Admin">Admin</option>
															</select>
															<label
																htmlFor="role"
																className="login-text-override"
															>
																Role
															</label>
														</div>
													</div>
												</div>
												<div className="col-12 text-center">
													<button
														className="btn btn-light w-50"
														type="submit"
														disabled={this.state.loading}
													>
														{this.state.loading ? (
															<div
																className="spinner-border"
																role="status"
															>
																<span className="sr-only"></span>
															</div>
														) : (
															"Login"
														)}
													</button>
												</div>
												<div className="col-12 text-center">
													<p className="small mb-0">
														<Link
															type="button"
															data-bs-toggle="modal"
															data-bs-target="#addItem"
															className="badge badge-info"
														>
															Forgotten Password?
														</Link>
													</p>
												</div>
												<div className="col-12 text-center">
													<p className="small mb-0">
														Necessary cookies are used across this site.
													</p>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div
					className="modal fade"
					id="addItem"
					tabIndex="-1"
					aria-labelledby="addItemLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-lg">
						<div className="modal-content bg-purple">
							<div className="modal-header">
								<h5 className="modal-title" id="addItemLabel">
									Password Reset
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body bg-light-purple margin rounded">
								<form
									className="row g-3 needs-validation"
									onSubmit={this.submitPasswordRecovery}
								>
									<div className="container padding">
										<div className="row">
											<div className="col text-center">
												If you have forgotten your password, please enter your email
												address below. We will send you a password reset link if you
												have an account with us. If you've forgotten your email
												address, please contact the website administrator or Phoenix
												staff.
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="row margin">
												<div className="col">
													<div className="input-group has-validation">
														<div className="form-floating">
															<input
																type="text"
																className="form-control"
																id="recovery_email"
																placeholder="recovery_email"
																onChange={this.handleInputChange}
																value={this.state.values.recovery_email}
																required
															/>
															<label
																htmlFor="recovery_email"
																className="login-text-override"
															>
																Email
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="row margin">
												<div className="col">{this.state.msg}</div>
												<div className="col text-right">
													<button
														className="btn bg-purple"
														type="submit"
														disabled={this.state.msg}
													>
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Login;
